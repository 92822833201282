import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { 
  Plane, LayoutDashboard, LogOut, User, Menu, X, ChevronDown,
  BookOpen, BarChart2, Bot, Settings
} from 'lucide-react';
import { clsx } from 'clsx';
import { useAuth } from '../contexts/AuthContext';

export default function Navigation() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { user, logout } = useAuth();

  const isActive = (path: string) => location.pathname === path;

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Error al cerrar sesión:', error);
    }
    setIsProfileOpen(false);
    setIsMenuOpen(false);
  };

  const handleNavigation = (path: string) => {
    navigate(path);
    setIsMenuOpen(false);
    setIsProfileOpen(false);
  };

  return (
    <nav className="fixed top-0 z-50 w-full bg-white/95 backdrop-blur-lg border-b border-gray-200">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          {/* Logo and Mobile Menu */}
          <div className="flex items-center">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="inline-flex items-center justify-center p-2 text-gray-500 rounded-xl lg:hidden hover:text-gray-900 focus:outline-none"
            >
              {isMenuOpen ? (
                <X className="w-6 h-6" />
              ) : (
                <Menu className="w-6 h-6" />
              )}
            </button>

            <div 
              onClick={() => handleNavigation('/dashboard')}
              className="flex items-center ml-4 space-x-3 cursor-pointer"
            >
              <div className="flex items-center justify-center w-10 h-10 bg-gradient-to-br from-blue-600 to-indigo-600 rounded-xl shadow-lg shadow-blue-500/30">
                <Plane className="w-6 h-6 text-white transform -rotate-45" />
              </div>
              <div>
                <span className="text-xl font-bold text-gray-900">LogBook</span>
                <span className="block text-xs text-gray-500">Registro Profesional</span>
              </div>
            </div>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden lg:flex lg:items-center lg:space-x-1">
            <button
              onClick={() => handleNavigation('/dashboard')}
              className={clsx(
                'px-4 py-2 rounded-lg transition-colors',
                isActive('/dashboard') ? 'text-blue-600 bg-blue-50' : 'text-gray-600 hover:bg-gray-50'
              )}
            >
              <div className="flex items-center space-x-2">
                <LayoutDashboard className="w-5 h-5" />
                <span>Panel</span>
              </div>
            </button>

            <button
              onClick={() => handleNavigation('/mi-registro')}
              className={clsx(
                'px-4 py-2 rounded-lg transition-colors',
                isActive('/mi-registro') ? 'text-blue-600 bg-blue-50' : 'text-gray-600 hover:bg-gray-50'
              )}
            >
              <div className="flex items-center space-x-2">
                <BookOpen className="w-5 h-5" />
                <span>Mi Registro</span>
              </div>
            </button>

            <button
              onClick={() => handleNavigation('/aeronaves')}
              className={clsx(
                'px-4 py-2 rounded-lg transition-colors',
                isActive('/aeronaves') ? 'text-blue-600 bg-blue-50' : 'text-gray-600 hover:bg-gray-50'
              )}
            >
              <div className="flex items-center space-x-2">
                <Plane className="w-5 h-5" />
                <span>Aeronaves</span>
              </div>
            </button>

            <button
              onClick={() => handleNavigation('/estadisticas')}
              className={clsx(
                'px-4 py-2 rounded-lg transition-colors',
                isActive('/estadisticas') ? 'text-blue-600 bg-blue-50' : 'text-gray-600 hover:bg-gray-50'
              )}
            >
              <div className="flex items-center space-x-2">
                <BarChart2 className="w-5 h-5" />
                <span>Estadísticas</span>
              </div>
            </button>

            <button
              onClick={() => handleNavigation('/consultas-ia')}
              className={clsx(
                'px-4 py-2 rounded-lg transition-colors',
                isActive('/consultas-ia') ? 'text-blue-600 bg-blue-50' : 'text-gray-600 hover:bg-gray-50'
              )}
            >
              <div className="flex items-center space-x-2">
                <Bot className="w-5 h-5" />
                <span>Consultas IA</span>
              </div>
            </button>

            {user?.role === 'admin' && (
              <button
                onClick={() => handleNavigation('/admin')}
                className={clsx(
                  'px-4 py-2 rounded-lg transition-colors',
                  isActive('/admin') ? 'text-blue-600 bg-blue-50' : 'text-gray-600 hover:bg-gray-50'
                )}
              >
                <div className="flex items-center space-x-2">
                  <Settings className="w-5 h-5" />
                  <span>Admin</span>
                </div>
              </button>
            )}
          </div>

          {/* User Profile */}
          <div className="flex items-center space-x-4">
            <div className="relative">
              <button
                onClick={() => setIsProfileOpen(!isProfileOpen)}
                className="flex items-center space-x-2 p-2 rounded-lg hover:bg-gray-50 transition-colors"
              >
                <div className="w-8 h-8 rounded-lg bg-gradient-to-br from-blue-500 to-blue-600 flex items-center justify-center text-white font-medium">
                  {user?.name?.charAt(0) || user?.email?.charAt(0) || 'U'}
                </div>
                <ChevronDown className="w-4 h-4 text-gray-500" />
              </button>

              {isProfileOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg border border-gray-200 py-1">
                  <button
                    onClick={() => handleNavigation('/perfil')}
                    className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
                  >
                    <User className="w-4 h-4 mr-2" />
                    Mi Perfil
                  </button>
                  <button
                    onClick={handleLogout}
                    className="w-full flex items-center px-4 py-2 text-sm text-red-600 hover:bg-red-50"
                  >
                    <LogOut className="w-4 h-4 mr-2" />
                    Cerrar Sesión
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className="lg:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1">
            <button
              onClick={() => handleNavigation('/dashboard')}
              className={clsx(
                'block w-full text-left px-3 py-2 rounded-lg text-base font-medium',
                isActive('/dashboard') ? 'bg-blue-50 text-blue-600' : 'text-gray-600 hover:bg-gray-50'
              )}
            >
              <div className="flex items-center space-x-2">
                <LayoutDashboard className="w-5 h-5" />
                <span>Panel</span>
              </div>
            </button>

            <button
              onClick={() => handleNavigation('/mi-registro')}
              className={clsx(
                'block w-full text-left px-3 py-2 rounded-lg text-base font-medium',
                isActive('/mi-registro') ? 'bg-blue-50 text-blue-600' : 'text-gray-600 hover:bg-gray-50'
              )}
            >
              <div className="flex items-center space-x-2">
                <BookOpen className="w-5 h-5" />
                <span>Mi Registro</span>
              </div>
            </button>

            <button
              onClick={() => handleNavigation('/aeronaves')}
              className={clsx(
                'block w-full text-left px-3 py-2 rounded-lg text-base font-medium',
                isActive('/aeronaves') ? 'bg-blue-50 text-blue-600' : 'text-gray-600 hover:bg-gray-50'
              )}
            >
              <div className="flex items-center space-x-2">
                <Plane className="w-5 h-5" />
                <span>Aeronaves</span>
              </div>
            </button>

            <button
              onClick={() => handleNavigation('/estadisticas')}
              className={clsx(
                'block w-full text-left px-3 py-2 rounded-lg text-base font-medium',
                isActive('/estadisticas') ? 'bg-blue-50 text-blue-600' : 'text-gray-600 hover:bg-gray-50'
              )}
            >
              <div className="flex items-center space-x-2">
                <BarChart2 className="w-5 h-5" />
                <span>Estadísticas</span>
              </div>
            </button>

            <button
              onClick={() => handleNavigation('/consultas-ia')}
              className={clsx(
                'block w-full text-left px-3 py-2 rounded-lg text-base font-medium',
                isActive('/consultas-ia') ? 'bg-blue-50 text-blue-600' : 'text-gray-600 hover:bg-gray-50'
              )}
            >
              <div className="flex items-center space-x-2">
                <Bot className="w-5 h-5" />
                <span>Consultas IA</span>
              </div>
            </button>

            {user?.role === 'admin' && (
              <button
                onClick={() => handleNavigation('/admin')}
                className={clsx(
                  'block w-full text-left px-3 py-2 rounded-lg text-base font-medium',
                  isActive('/admin') ? 'bg-blue-50 text-blue-600' : 'text-gray-600 hover:bg-gray-50'
                )}
              >
                <div className="flex items-center space-x-2">
                  <Settings className="w-5 h-5" />
                  <span>Admin</span>
                </div>
              </button>
            )}

            <button
              onClick={handleLogout}
              className="block w-full text-left px-3 py-2 rounded-lg text-base font-medium text-red-600 hover:bg-red-50"
            >
              <div className="flex items-center space-x-2">
                <LogOut className="w-5 h-5" />
                <span>Cerrar Sesión</span>
              </div>
            </button>
          </div>
        </div>
      )}
    </nav>
  );
}