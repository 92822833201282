import React, { useState } from 'react';
import { Aircraft, AircraftType, DroneType } from '../../types/aircraft';
import { Dialog } from '@headlessui/react';
import { 
  X, 
  Plane, 
  Calendar, 
  Hash, 
  Info,
  PlaneTakeoff,
  Drone,
  BarChart2
} from 'lucide-react';

interface AircraftFormProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (aircraft: Omit<Aircraft, 'id'>) => void;
}

export function AircraftForm({ isOpen, onClose, onSubmit }: AircraftFormProps) {
  const [formData, setFormData] = useState({
    registration: '',
    type: 'avion' as AircraftType | DroneType,
    model: '',
    category: 'airplane' as Aircraft['category'],
    serialNumber: '',
    yearOfManufacture: new Date().getFullYear(),
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
    onClose();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    setFormData(prev => {
      const newData = {
        ...prev,
        [name]: type === 'number' ? Number(value) : value,
      };

      if (name === 'category') {
        newData.type = value === 'airplane' ? 'avion' : 'multirrotor';
        // Limpiar matrícula/número de serie al cambiar categoría
        newData.registration = '';
        newData.serialNumber = '';
      }

      return newData;
    });
  };

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30 backdrop-blur-sm" aria-hidden="true" />
      
      <div className="fixed inset-0 flex items-center justify-center p-4 overflow-y-auto">
        <Dialog.Panel className="mx-auto max-w-2xl w-full bg-white rounded-2xl shadow-xl my-8">
          <form onSubmit={handleSubmit} className="space-y-8">
            {/* Header */}
            <div className="flex justify-between items-center p-6 border-b border-gray-200">
              <div>
                <Dialog.Title className="text-2xl font-bold text-gray-900">
                  Registrar Nueva Aeronave
                </Dialog.Title>
                <p className="mt-1 text-sm text-gray-500">
                  Ingresa los detalles de la nueva aeronave para tu flota
                </p>
              </div>
              <button
                type="button"
                onClick={onClose}
                className="p-2 text-gray-400 hover:text-gray-500 rounded-full hover:bg-gray-100 transition-colors"
              >
                <X className="h-5 w-5" />
              </button>
            </div>

            <div className="px-6 space-y-8">
              {/* Tipo de Aeronave */}
              <div className="bg-blue-50/50 rounded-2xl p-6 border border-blue-100">
                <div className="flex items-center space-x-3 mb-6">
                  <div className="p-2 bg-blue-100 rounded-xl">
                    <BarChart2 className="h-5 w-5 text-blue-600" />
                  </div>
                  <h4 className="font-semibold text-blue-900">Tipo de Aeronave</h4>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <button
                    type="button"
                    onClick={() => setFormData(prev => ({ 
                      ...prev, 
                      category: 'airplane',
                      type: 'avion',
                      registration: '',
                      serialNumber: ''
                    }))}
                    className={`relative p-4 rounded-xl border-2 transition-all ${
                      formData.category === 'airplane'
                        ? 'border-blue-600 bg-blue-50'
                        : 'border-gray-200 hover:border-blue-200'
                    }`}
                  >
                    <div className="flex flex-col items-center">
                      <Plane className={`h-8 w-8 mb-2 ${
                        formData.category === 'airplane' ? 'text-blue-600' : 'text-gray-400'
                      }`} />
                      <span className={`font-medium ${
                        formData.category === 'airplane' ? 'text-blue-900' : 'text-gray-600'
                      }`}>
                        Aeronave
                      </span>
                    </div>
                  </button>
                  <button
                    type="button"
                    onClick={() => setFormData(prev => ({ 
                      ...prev, 
                      category: 'drone',
                      type: 'multirrotor',
                      registration: '',
                      serialNumber: ''
                    }))}
                    className={`relative p-4 rounded-xl border-2 transition-all ${
                      formData.category === 'drone'
                        ? 'border-blue-600 bg-blue-50'
                        : 'border-gray-200 hover:border-blue-200'
                    }`}
                  >
                    <div className="flex flex-col items-center">
                      <PlaneTakeoff className={`h-8 w-8 mb-2 ${
                        formData.category === 'drone' ? 'text-blue-600' : 'text-gray-400'
                      }`} />
                      <span className={`font-medium ${
                        formData.category === 'drone' ? 'text-blue-900' : 'text-gray-600'
                      }`}>
                        Dron
                      </span>
                    </div>
                  </button>
                </div>
              </div>

              {/* Información Básica */}
              <div className="bg-purple-50/50 rounded-2xl p-6 border border-purple-100">
                <div className="flex items-center space-x-3 mb-6">
                  <div className="p-2 bg-purple-100 rounded-xl">
                    <Info className="h-5 w-5 text-purple-600" />
                  </div>
                  <h4 className="font-semibold text-purple-900">Información Básica</h4>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {formData.category === 'airplane' ? (
                    <div>
                      <label className="block text-sm font-medium text-purple-900 mb-2">
                        Matrícula
                      </label>
                      <input
                        type="text"
                        name="registration"
                        required
                        value={formData.registration}
                        onChange={handleChange}
                        placeholder="EC-XXX"
                        className="w-full rounded-xl border-purple-200 focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50 bg-white"
                      />
                    </div>
                  ) : (
                    <div>
                      <label className="block text-sm font-medium text-purple-900 mb-2">
                        Número de Serie
                      </label>
                      <input
                        type="text"
                        name="serialNumber"
                        required
                        value={formData.serialNumber}
                        onChange={handleChange}
                        placeholder="DJI123456789"
                        className="w-full rounded-xl border-purple-200 focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50 bg-white"
                      />
                    </div>
                  )}
                  <div>
                    <label className="block text-sm font-medium text-purple-900 mb-2">
                      Tipo
                    </label>
                    <select
                      name="type"
                      required
                      value={formData.type}
                      onChange={handleChange}
                      className="w-full rounded-xl border-purple-200 focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50 bg-white"
                    >
                      {formData.category === 'airplane' ? (
                        <>
                          <option value="avion">Avión</option>
                          <option value="helicoptero">Helicóptero</option>
                          <option value="autogiro">Autogiro</option>
                        </>
                      ) : (
                        <>
                          <option value="ala-fija">Ala Fija</option>
                          <option value="multirrotor">Multirrotor</option>
                          <option value="helicoptero">Helicóptero</option>
                        </>
                      )}
                    </select>
                  </div>
                </div>
              </div>

              {/* Detalles Técnicos */}
              <div className="bg-emerald-50/50 rounded-2xl p-6 border border-emerald-100">
                <div className="flex items-center space-x-3 mb-6">
                  <div className="p-2 bg-emerald-100 rounded-xl">
                    <Hash className="h-5 w-5 text-emerald-600" />
                  </div>
                  <h4 className="font-semibold text-emerald-900">Detalles Técnicos</h4>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <label className="block text-sm font-medium text-emerald-900 mb-2">
                      Modelo
                    </label>
                    <input
                      type="text"
                      name="model"
                      required
                      value={formData.model}
                      onChange={handleChange}
                      placeholder={formData.category === 'airplane' ? 'Cessna 172' : 'DJI Mavic 3'}
                      className="w-full rounded-xl border-emerald-200 focus:border-emerald-300 focus:ring focus:ring-emerald-200 focus:ring-opacity-50 bg-white"
                    />
                  </div>
                  {formData.category === 'airplane' && (
                    <div>
                      <label className="block text-sm font-medium text-emerald-900 mb-2">
                        Número de Serie
                      </label>
                      <input
                        type="text"
                        name="serialNumber"
                        required
                        value={formData.serialNumber}
                        onChange={handleChange}
                        className="w-full rounded-xl border-emerald-200 focus:border-emerald-300 focus:ring focus:ring-emerald-200 focus:ring-opacity-50 bg-white"
                      />
                    </div>
                  )}
                </div>
              </div>

              {/* Año de Fabricación */}
              <div className="bg-amber-50/50 rounded-2xl p-6 border border-amber-100">
                <div className="flex items-center space-x-3 mb-6">
                  <div className="p-2 bg-amber-100 rounded-xl">
                    <Calendar className="h-5 w-5 text-amber-600" />
                  </div>
                  <h4 className="font-semibold text-amber-900">Año de Fabricación</h4>
                </div>
                <input
                  type="number"
                  name="yearOfManufacture"
                  min="1900"
                  max={new Date().getFullYear()}
                  required
                  value={formData.yearOfManufacture}
                  onChange={handleChange}
                  className="w-full rounded-xl border-amber-200 focus:border-amber-300 focus:ring focus:ring-amber-200 focus:ring-opacity-50 bg-white"
                />
              </div>
            </div>

            {/* Footer */}
            <div className="flex justify-end space-x-4 p-6 border-t border-gray-200">
              <button
                type="button"
                onClick={onClose}
                className="px-6 py-2.5 border border-gray-300 rounded-xl text-gray-700 font-medium hover:bg-gray-50 transition-colors"
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="px-6 py-2.5 bg-gradient-to-r from-blue-600 to-blue-700 text-white rounded-xl font-medium hover:from-blue-700 hover:to-blue-800 transition-colors shadow-lg shadow-blue-500/30"
              >
                Registrar Aeronave
              </button>
            </div>
          </form>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}