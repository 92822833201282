import { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { 
  User as FirebaseUser,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut as firebaseSignOut,
  onAuthStateChanged,
  sendEmailVerification,
  sendPasswordResetEmail
} from 'firebase/auth';
import { doc, setDoc, getDoc, serverTimestamp } from 'firebase/firestore';
import { auth, db } from '../lib/firebase';
import { useNavigate } from 'react-router-dom';

interface User extends FirebaseUser {
  role?: 'admin' | 'user';
  name?: string;
  phone?: string;
  address?: string;
  company?: string;
  status?: 'active' | 'suspended';
  lastLoginAt?: string;
}

interface AuthContextType {
  user: User | null;
  login: (email: string, password: string) => Promise<void>;
  register: (email: string, password: string, userData: Omit<User, keyof FirebaseUser>) => Promise<void>;
  logout: () => Promise<void>;
  loading: boolean;
  sendVerificationEmail: () => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
}

const AuthContext = createContext<AuthContextType | null>(null);

export function AuthProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        try {
          const userDocRef = doc(db, 'users', firebaseUser.uid);
          const userDoc = await getDoc(userDocRef);
          
          if (!userDoc.exists()) {
            // Create initial user document if it doesn't exist
            const initialUserData = {
              email: firebaseUser.email,
              role: 'user',
              status: 'active',
              createdAt: serverTimestamp(),
              updatedAt: serverTimestamp(),
              lastLoginAt: new Date().toISOString()
            };
            await setDoc(userDocRef, initialUserData);
            
            setUser({
              ...firebaseUser,
              ...initialUserData
            });
          } else {
            const userData = userDoc.data();
            
            // Check if user is suspended (except for admins)
            if (userData.status === 'suspended' && userData.role !== 'admin') {
              await firebaseSignOut(auth);
              setUser(null);
              throw new Error('Tu cuenta está suspendida. Contacta con el administrador.');
            }

            // Update last login time
            await setDoc(userDocRef, {
              ...userData,
              lastLoginAt: new Date().toISOString(),
              updatedAt: serverTimestamp()
            }, { merge: true });

            setUser({
              ...firebaseUser,
              ...userData
            });
          }
        } catch (error) {
          console.error('Error initializing user data:', error);
          await firebaseSignOut(auth);
          setUser(null);
          if (error instanceof Error) {
            throw error;
          }
        }
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [navigate]);

  const login = async (email: string, password: string) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      
      if (!userCredential.user.emailVerified) {
        throw new Error('Por favor, verifica tu correo electrónico antes de iniciar sesión.');
      }

      const userDoc = await getDoc(doc(db, 'users', userCredential.user.uid));
      const userData = userDoc.data();
      
      if (userData?.status === 'suspended' && userData?.role !== 'admin') {
        throw new Error('Tu cuenta está suspendida. Contacta con el administrador.');
      }

      setUser({
        ...userCredential.user,
        ...userData
      });

      navigate('/dashboard');
    } catch (error) {
      console.error('Error al iniciar sesión:', error);
      throw error;
    }
  };

  const register = async (email: string, password: string, userData: Omit<User, keyof FirebaseUser>) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      
      await sendEmailVerification(userCredential.user);
      
      await setDoc(doc(db, 'users', userCredential.user.uid), {
        email,
        role: 'user',
        status: 'active',
        ...userData,
        emailVerified: false,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        lastLoginAt: new Date().toISOString()
      });

      await firebaseSignOut(auth);
      setUser(null);

      throw new Error('Se ha enviado un correo de verificación. Por favor, verifica tu email antes de iniciar sesión.');
    } catch (error) {
      console.error('Error al registrar usuario:', error);
      throw error;
    }
  };

  const sendVerificationEmail = async () => {
    if (auth.currentUser && !auth.currentUser.emailVerified) {
      await sendEmailVerification(auth.currentUser);
    }
  };

  const resetPassword = async (email: string) => {
    await sendPasswordResetEmail(auth, email);
  };

  const logout = async () => {
    try {
      await firebaseSignOut(auth);
      setUser(null);
      navigate('/');
    } catch (error) {
      console.error('Error al cerrar sesión:', error);
      throw error;
    }
  };

  return (
    <AuthContext.Provider value={{ 
      user, 
      login, 
      register, 
      logout, 
      loading,
      sendVerificationEmail,
      resetPassword
    }}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}