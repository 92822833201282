import React from 'react';
import { Users, Activity, Plane, DollarSign } from 'lucide-react';

interface AdminStatsProps {
  stats: {
    activeUsers: number;
    totalUsers: number;
    totalFlights: number;
    totalAircraft: number;
    monthlyRevenue: number;
    yearlyRevenue: number;
    activeSubscriptions: number;
  };
}

export function AdminStats({ stats }: AdminStatsProps) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
      <div className="bg-white rounded-xl p-6 border border-gray-200">
        <div className="flex items-center">
          <div className="inline-flex items-center justify-center w-12 h-12 rounded-lg bg-blue-50 text-blue-600">
            <Users className="h-6 w-6" />
          </div>
          <div className="ml-4">
            <h3 className="text-2xl font-bold text-gray-900">{stats.totalUsers}</h3>
            <p className="text-sm text-gray-500">Usuarios Totales</p>
          </div>
        </div>
        <div className="mt-4 text-sm text-green-600">
          {stats.activeUsers} usuarios activos
        </div>
      </div>

      <div className="bg-white rounded-xl p-6 border border-gray-200">
        <div className="flex items-center">
          <div className="inline-flex items-center justify-center w-12 h-12 rounded-lg bg-green-50 text-green-600">
            <Activity className="h-6 w-6" />
          </div>
          <div className="ml-4">
            <h3 className="text-2xl font-bold text-gray-900">{stats.totalFlights}</h3>
            <p className="text-sm text-gray-500">Vuelos Registrados</p>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-xl p-6 border border-gray-200">
        <div className="flex items-center">
          <div className="inline-flex items-center justify-center w-12 h-12 rounded-lg bg-purple-50 text-purple-600">
            <Plane className="h-6 w-6" />
          </div>
          <div className="ml-4">
            <h3 className="text-2xl font-bold text-gray-900">{stats.totalAircraft}</h3>
            <p className="text-sm text-gray-500">Aeronaves</p>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-xl p-6 border border-gray-200">
        <div className="flex items-center">
          <div className="inline-flex items-center justify-center w-12 h-12 rounded-lg bg-amber-50 text-amber-600">
            <DollarSign className="h-6 w-6" />
          </div>
          <div className="ml-4">
            <h3 className="text-2xl font-bold text-gray-900">{stats.yearlyRevenue}€</h3>
            <p className="text-sm text-gray-500">Ingresos Anuales</p>
          </div>
        </div>
        <div className="mt-4 text-sm text-green-600">
          +{stats.monthlyRevenue}€/mes
        </div>
      </div>
    </div>
  );
}