import React from 'react';

export function AIQueries() {
  return (
    <div className="space-y-8">
      <div className="relative h-[200px] -mx-4 -mt-4 overflow-hidden rounded-xl">
        <img
          className="w-full h-full object-cover"
          src="https://images.unsplash.com/photo-1677442136019-21780ecad995?auto=format&fit=crop&q=80"
          alt="Banner IA"
        />
        <div className="absolute inset-0 bg-gradient-to-r from-blue-900/90 via-blue-800/70 to-transparent"></div>
        <div className="absolute inset-0 flex flex-col justify-center px-8">
          <div className="max-w-2xl">
            <h1 className="text-4xl font-bold text-white mb-4">
              Consultas IA
            </h1>
            <p className="text-xl text-blue-100">
              Asistente virtual especializado en aviación
            </p>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
        <iframe
          src="https://www.chatbase.co/chatbot-iframe/XJv4-EtajL1wQ-FSszFv3"
          width="100%"
          style={{ height: '700px', minHeight: '700px' }}
          title="AI Assistant"
          frameBorder="0"
        />
      </div>
    </div>
  );
}