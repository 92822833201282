import React, { useState } from 'react';
import { Plus, Clock, Calendar, MapPin, Award, PlaneTakeoff, Download, Loader2, AlertCircle } from 'lucide-react';
import { collection, addDoc, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Flight } from '../types/flight';
import { FlightList } from '../components/flights/FlightList';
import { FlightForm } from '../components/flights/FlightForm';
import { useFlightStats } from '../hooks/useFlightStats';
import { useAuth } from '../contexts/AuthContext';
import { useFlights } from '../hooks/useFlights';
import { ExportButton } from '../components/flights/ExportButton';

export function PilotLogbook() {
  const [showForm, setShowForm] = useState(false);
  const [selectedFlight, setSelectedFlight] = useState<Flight | null>(null);
  const { user } = useAuth();
  const { stats, loading: statsLoading } = useFlightStats();
  const { flights, loading: flightsLoading, error, setFlights } = useFlights();

  const handleSubmit = async (flightData: Omit<Flight, 'id'>) => {
    if (!user?.uid) return;

    try {
      const flightsRef = collection(db, `users/${user.uid}/flights`);
      const newFlightData = {
        ...flightData,
        userId: user.uid,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      };

      const docRef = await addDoc(flightsRef, newFlightData);
      const newFlight = { id: docRef.id, ...newFlightData } as Flight;
      
      setFlights(prev => [newFlight, ...prev]);
      setShowForm(false);
      setSelectedFlight(null);
    } catch (error) {
      console.error('Error adding flight:', error);
      alert('Error al guardar el vuelo');
    }
  };

  const handleEdit = async (updatedFlight: Flight) => {
    if (!user?.uid) return;

    try {
      const flightRef = doc(db, `users/${user.uid}/flights`, updatedFlight.id);
      await updateDoc(flightRef, {
        ...updatedFlight,
        updatedAt: new Date().toISOString()
      });
      
      setFlights(prev => prev.map(f => 
        f.id === updatedFlight.id ? updatedFlight : f
      ));
      setSelectedFlight(null);
      setShowForm(false);
    } catch (error) {
      console.error('Error updating flight:', error);
      alert('Error al actualizar el vuelo');
    }
  };

  const handleDelete = async (flightId: string) => {
    if (!user?.uid || !window.confirm('¿Estás seguro de que deseas eliminar este vuelo?')) {
      return;
    }

    try {
      const flightRef = doc(db, `users/${user.uid}/flights`, flightId);
      await deleteDoc(flightRef);
      setFlights(prev => prev.filter(f => f.id !== flightId));
    } catch (error) {
      console.error('Error deleting flight:', error);
      alert('Error al eliminar el vuelo');
    }
  };

  if (flightsLoading || statsLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="flex flex-col items-center space-y-4">
          <Loader2 className="h-8 w-8 animate-spin text-blue-600" />
          <p className="text-gray-600">Cargando registro de vuelos...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-center space-y-4">
          <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-red-100">
            <AlertCircle className="h-8 w-8 text-red-600" />
          </div>
          <p className="text-red-600">{error}</p>
          <button 
            onClick={() => window.location.reload()}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
          >
            Reintentar
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      {/* Banner */}
      <div className="relative h-[300px] -mx-4 -mt-4 overflow-hidden rounded-xl">
        <img
          className="w-full h-full object-cover"
          src="https://images.unsplash.com/photo-1436891620584-47fd0e565afb?auto=format&fit=crop&q=80"
          alt="Banner registro de vuelos"
        />
        <div className="absolute inset-0 bg-gradient-to-r from-blue-900/90 to-blue-800/70"></div>
        <div className="absolute inset-0 flex items-center px-8">
          <div className="max-w-3xl">
            <h1 className="text-4xl font-bold text-white mb-4">
              Mi Registro de Vuelos
            </h1>
            <p className="text-xl text-blue-100 mb-8">
              Gestiona y mantén un registro detallado de toda tu actividad aérea
            </p>
            <div className="flex flex-wrap gap-4">
              <button
                onClick={() => {
                  setSelectedFlight(null);
                  setShowForm(true);
                }}
                className="inline-flex items-center px-6 py-3 bg-white text-blue-900 text-base font-medium rounded-lg hover:bg-blue-50 transition-all duration-200 transform hover:scale-105 shadow-lg"
              >
                <Plus className="h-5 w-5 mr-2" />
                Registrar Nuevo Vuelo
              </button>
              {user && flights.length > 0 && (
                <ExportButton 
                  flights={flights} 
                  user={user} 
                  totalHours={stats.totalHours} 
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Stats */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
        <div className="bg-white rounded-xl p-6 border border-gray-200">
          <div className="flex items-center space-x-3">
            <div className="p-2 bg-blue-100 rounded-lg">
              <PlaneTakeoff className="h-5 w-5 text-blue-600" />
            </div>
            <div>
              <p className="text-sm text-gray-500">Total Vuelos</p>
              <p className="text-2xl font-bold text-gray-900">{stats.totalFlights}</p>
            </div>
          </div>
        </div>
        
        <div className="bg-white rounded-xl p-6 border border-gray-200">
          <div className="flex items-center space-x-3">
            <div className="p-2 bg-green-100 rounded-lg">
              <Clock className="h-5 w-5 text-green-600" />
            </div>
            <div>
              <p className="text-sm text-gray-500">Horas Totales</p>
              <p className="text-2xl font-bold text-gray-900">{stats.totalHours}h</p>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-xl p-6 border border-gray-200">
          <div className="flex items-center space-x-3">
            <div className="p-2 bg-purple-100 rounded-lg">
              <MapPin className="h-5 w-5 text-purple-600" />
            </div>
            <div>
              <p className="text-sm text-gray-500">Aeródromos</p>
              <p className="text-2xl font-bold text-gray-900">{stats.airports}</p>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-xl p-6 border border-gray-200">
          <div className="flex items-center space-x-3">
            <div className="p-2 bg-amber-100 rounded-lg">
              <Award className="h-5 w-5 text-amber-600" />
            </div>
            <div>
              <p className="text-sm text-gray-500">Licencias</p>
              <p className="text-2xl font-bold text-gray-900">{stats.licenses}</p>
            </div>
          </div>
        </div>
      </div>

      {/* Flight List or Form */}
      <div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
        <div className="border-b border-gray-200 px-6 py-4">
          <div className="flex items-center justify-between">
            <div>
              <h2 className="text-lg font-semibold text-gray-900">Historial de Vuelos</h2>
              <p className="text-sm text-gray-500">
                Registro completo de todos tus vuelos
              </p>
            </div>
            <button
              onClick={() => {
                setSelectedFlight(null);
                setShowForm(true);
              }}
              className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
            >
              <Plus className="h-4 w-4 mr-2" />
              Registrar Vuelo
            </button>
          </div>
        </div>

        <div className="p-6">
          {showForm ? (
            <FlightForm
              onSubmit={selectedFlight ? handleEdit : handleSubmit}
              onCancel={() => {
                setShowForm(false);
                setSelectedFlight(null);
              }}
              initialFlight={selectedFlight}
            />
          ) : flights.length > 0 ? (
            <FlightList
              flights={flights}
              onEdit={(flight) => {
                setSelectedFlight(flight);
                setShowForm(true);
              }}
              onDelete={handleDelete}
            />
          ) : (
            <div className="text-center py-12">
              <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-blue-50 text-blue-600 mb-4">
                <PlaneTakeoff className="h-8 w-8" />
              </div>
              <h3 className="text-lg font-medium text-gray-900 mb-2">
                No hay vuelos registrados
              </h3>
              <p className="text-gray-500 mb-6">
                Comienza registrando tu primer vuelo
              </p>
              <button
                onClick={() => {
                  setSelectedFlight(null);
                  setShowForm(true);
                }}
                className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
              >
                <Plus className="h-4 w-4 mr-2" />
                Registrar Primer Vuelo
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}