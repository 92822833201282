import React from 'react';
import { Link } from 'react-router-dom';
import { 
  Plane, 
  ArrowRight, 
  BookOpen, 
  Bot, 
  BarChart2, 
  CheckCircle,
  Shield
} from 'lucide-react';
import { Footer } from '../components/layout/Footer';

export function Landing() {
  return (
    <div className="min-h-screen flex flex-col">
      {/* Hero Section */}
      <div className="relative min-h-[calc(100vh-64px)]">
        <div className="absolute inset-0">
          <img
            src="https://images.unsplash.com/photo-1559628376-f3fe5f782a2e?auto=format&fit=crop&q=80"
            alt="Hero background"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-r from-blue-900/95 via-blue-900/90 to-blue-800/80"></div>
        </div>

        <div className="relative flex flex-col min-h-[calc(100vh-64px)]">
          {/* Navigation */}
          <nav className="border-b border-white/10">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex items-center justify-between h-16">
                <div className="flex items-center space-x-3">
                  <div className="w-10 h-10 bg-white rounded-xl flex items-center justify-center">
                    <Plane className="w-6 h-6 text-blue-600 transform -rotate-45" />
                  </div>
                  <span className="text-xl sm:text-2xl font-bold text-white">LogBook</span>
                </div>
                <div className="flex items-center space-x-2 sm:space-x-4">
                  <Link 
                    to="/login"
                    className="text-sm sm:text-base text-white hover:text-blue-200 transition-colors px-3 py-2"
                  >
                    Iniciar Sesión
                  </Link>
                  <Link
                    to="/registro"
                    className="text-sm sm:text-base px-3 sm:px-4 py-2 bg-white text-blue-600 rounded-lg hover:bg-blue-50 transition-colors"
                  >
                    Crear Cuenta
                  </Link>
                </div>
              </div>
            </div>
          </nav>

          {/* Hero Content */}
          <div className="flex-grow flex items-center">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 sm:py-16">
              <div className="max-w-3xl">
                <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-400/10 text-blue-200 ring-1 ring-blue-400/30 mb-6 sm:mb-8">
                  Solo 60€/año · Todo incluido
                </span>
                <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-white mb-6 sm:mb-8 leading-tight">
                  Tu registro de vuelos profesional en la nube
                </h1>
                <p className="text-base sm:text-lg lg:text-xl text-blue-100 mb-8 leading-relaxed">
                  Gestiona tu actividad como piloto con el único software que integra registro de vuelos, 
                  asistente IA y análisis avanzado. Compatible con drones y aviación tripulada.
                </p>
                <div className="flex flex-col sm:flex-row gap-4">
                  <Link
                    to="/registro"
                    className="inline-flex items-center justify-center px-6 py-3 bg-gradient-to-r from-blue-500 to-blue-600 text-white rounded-lg hover:from-blue-600 hover:to-blue-700 transition-all transform hover:scale-105 shadow-lg text-base sm:text-lg"
                  >
                    Empezar ahora
                    <ArrowRight className="ml-2 h-5 w-5" />
                  </Link>
                  <a
                    href="#pricing"
                    className="inline-flex items-center justify-center px-6 py-3 bg-white/10 text-white rounded-lg hover:bg-white/20 transition-all text-base sm:text-lg"
                  >
                    Ver características
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Stats */}
          <div className="bg-gradient-to-t from-black/50 to-transparent py-8 sm:py-12">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-8">
                <div className="text-center">
                  <div className="text-2xl sm:text-3xl font-bold text-white">10,000+</div>
                  <div className="text-sm text-blue-200">Vuelos Registrados</div>
                </div>
                <div className="text-center">
                  <div className="text-2xl sm:text-3xl font-bold text-white">1,000+</div>
                  <div className="text-sm text-blue-200">Pilotos Activos</div>
                </div>
                <div className="text-center">
                  <div className="text-2xl sm:text-3xl font-bold text-white">98%</div>
                  <div className="text-sm text-blue-200">Satisfacción</div>
                </div>
                <div className="text-center">
                  <div className="text-2xl sm:text-3xl font-bold text-white">24/7</div>
                  <div className="text-sm text-blue-200">Soporte IA</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Features */}
      <div className="py-16 sm:py-24 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12 sm:mb-16">
            <h2 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-4">
              Todo lo que necesitas en una única plataforma
            </h2>
            <p className="text-base sm:text-lg text-gray-600">
              Diseñado específicamente para pilotos profesionales de drones y aviación tripulada
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8">
            <div className="bg-gray-50 p-6 sm:p-8 rounded-2xl border border-gray-100">
              <div className="w-12 h-12 bg-blue-100 rounded-xl flex items-center justify-center mb-6">
                <BookOpen className="h-6 w-6 text-blue-600" />
              </div>
              <h3 className="text-xl font-semibold text-gray-900 mb-4">
                Registro Digital Profesional
              </h3>
              <ul className="space-y-3 text-gray-600">
                <li className="flex items-center">
                  <CheckCircle className="h-5 w-5 text-green-500 mr-2 flex-shrink-0" />
                  <span>Registro oficial según normativa AESA</span>
                </li>
                <li className="flex items-center">
                  <CheckCircle className="h-5 w-5 text-green-500 mr-2 flex-shrink-0" />
                  <span>Exportación para inspecciones</span>
                </li>
                <li className="flex items-center">
                  <CheckCircle className="h-5 w-5 text-green-500 mr-2 flex-shrink-0" />
                  <span>Copias de seguridad automáticas</span>
                </li>
              </ul>
            </div>

            <div className="bg-gray-50 p-6 sm:p-8 rounded-2xl border border-gray-100">
              <div className="w-12 h-12 bg-purple-100 rounded-xl flex items-center justify-center mb-6">
                <Bot className="h-6 w-6 text-purple-600" />
              </div>
              <h3 className="text-xl font-semibold text-gray-900 mb-4">
                Asistente IA Especializado
              </h3>
              <ul className="space-y-3 text-gray-600">
                <li className="flex items-center">
                  <CheckCircle className="h-5 w-5 text-green-500 mr-2 flex-shrink-0" />
                  <span>Consultas sobre SERA y normativa AESA</span>
                </li>
                <li className="flex items-center">
                  <CheckCircle className="h-5 w-5 text-green-500 mr-2 flex-shrink-0" />
                  <span>Base de datos actualizada diariamente</span>
                </li>
                <li className="flex items-center">
                  <CheckCircle className="h-5 w-5 text-green-500 mr-2 flex-shrink-0" />
                  <span>Respuestas verificadas por expertos</span>
                </li>
              </ul>
            </div>

            <div className="bg-gray-50 p-6 sm:p-8 rounded-2xl border border-gray-100">
              <div className="w-12 h-12 bg-green-100 rounded-xl flex items-center justify-center mb-6">
                <BarChart2 className="h-6 w-6 text-green-600" />
              </div>
              <h3 className="text-xl font-semibold text-gray-900 mb-4">
                Análisis Avanzado
              </h3>
              <ul className="space-y-3 text-gray-600">
                <li className="flex items-center">
                  <CheckCircle className="h-5 w-5 text-green-500 mr-2 flex-shrink-0" />
                  <span>Control de horas y experiencia</span>
                </li>
                <li className="flex items-center">
                  <CheckCircle className="h-5 w-5 text-green-500 mr-2 flex-shrink-0" />
                  <span>Informes detallados por aeronave</span>
                </li>
                <li className="flex items-center">
                  <CheckCircle className="h-5 w-5 text-green-500 mr-2 flex-shrink-0" />
                  <span>Estadísticas para renovaciones</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Pricing */}
      <div id="pricing" className="py-16 sm:py-24 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto text-center mb-12 sm:mb-16">
            <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-100 text-blue-800 mb-6">
              Precio único
            </span>
            <h2 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-4">
              Un solo plan con todo incluido
            </h2>
            <p className="text-base sm:text-lg text-gray-600">
              60€/año · Sin costes ocultos · Sin sorpresas
            </p>
          </div>

          <div className="max-w-lg mx-auto bg-white rounded-2xl border border-gray-200 overflow-hidden shadow-xl">
            <div className="px-6 py-12">
              <div className="flex justify-center mb-8">
                <div className="w-20 h-20 bg-gradient-to-br from-blue-500 to-blue-600 rounded-2xl flex items-center justify-center">
                  <Shield className="h-10 w-10 text-white" />
                </div>
              </div>
              <h3 className="text-2xl font-bold text-gray-900 mb-2 text-center">Plan Profesional</h3>
              <div className="flex items-center justify-center mb-6">
                <span className="text-4xl sm:text-5xl font-bold text-gray-900">60€</span>
                <span className="text-gray-500 ml-2">/año</span>
              </div>

              <div className="space-y-4 mb-8">
                <div className="flex items-center space-x-3">
                  <CheckCircle className="h-5 w-5 text-green-500 flex-shrink-0" />
                  <span className="text-gray-600">Registro ilimitado de vuelos y aeronaves</span>
                </div>
                <div className="flex items-center space-x-3">
                  <CheckCircle className="h-5 w-5 text-green-500 flex-shrink-0" />
                  <span className="text-gray-600">Asistente IA con normativa actualizada</span>
                </div>
                <div className="flex items-center space-x-3">
                  <CheckCircle className="h-5 w-5 text-green-500 flex-shrink-0" />
                  <span className="text-gray-600">Estadísticas e informes profesionales</span>
                </div>
                <div className="flex items-center space-x-3">
                  <CheckCircle className="h-5 w-5 text-green-500 flex-shrink-0" />
                  <span className="text-gray-600">Exportación para AESA</span>
                </div>
                <div className="flex items-center space-x-3">
                  <CheckCircle className="h-5 w-5 text-green-500 flex-shrink-0" />
                  <span className="text-gray-600">Soporte técnico prioritario</span>
                </div>
                <div className="flex items-center space-x-3">
                  <CheckCircle className="h-5 w-5 text-green-500 flex-shrink-0" />
                  <span className="text-gray-600">Copias de seguridad automáticas</span>
                </div>
              </div>

              <Link
                to="/registro"
                className="inline-flex items-center px-8 py-3 bg-gradient-to-r from-blue-500 to-blue-600 text-white rounded-lg hover:from-blue-600 hover:to-blue-700 transition-all transform hover:scale-105 shadow-lg w-full justify-center"
              >
                Comenzar ahora
                <ArrowRight className="ml-2 h-5 w-5" />
              </Link>
              <p className="mt-4 text-sm text-gray-500 text-center">
                Pago único anual · Cancela cuando quieras
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* CTA */}
      <div className="bg-blue-900 py-12 sm:py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-2xl sm:text-3xl font-bold text-white mb-4">
              Gestiona tu actividad profesional
            </h2>
            <p className="text-lg sm:text-xl text-blue-200 mb-8">
              Únete a los pilotos que ya han digitalizado su registro de vuelos
            </p>
            <Link
              to="/registro"
              className="inline-flex items-center px-6 sm:px-8 py-3 bg-white text-blue-600 rounded-lg hover:bg-blue-50 transition-all transform hover:scale-105 shadow-lg text-base sm:text-lg"
            >
              Comenzar ahora
              <ArrowRight className="ml-2 h-5 w-5" />
            </Link>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}