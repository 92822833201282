import React, { useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { X, User, Mail, Phone, Building, Calendar, CreditCard, Shield, Loader2 } from 'lucide-react';
import { doc, updateDoc } from 'firebase/firestore';
import { createUserWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { db, auth } from '../../lib/firebase';
import type { User as UserType } from '../../types/user';

interface UserModalProps {
  isOpen: boolean;
  onClose: () => void;
  user?: UserType | null;
  mode: 'create' | 'edit';
  onSuccess: (user: UserType) => void;
}

export function UserModal({ isOpen, onClose, user, mode, onSuccess }: UserModalProps) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    phone: '',
    company: '',
    role: 'user' as 'admin' | 'user',
    status: 'active' as 'active' | 'suspended',
    dni: '',
    subscription: {
      plan: 'basic',
      validUntil: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000).toISOString()
    }
  });

  useEffect(() => {
    if (user && mode === 'edit') {
      setFormData({
        ...formData,
        name: user.name || '',
        email: user.email || '',
        phone: user.phone || '',
        company: user.company || '',
        role: user.role || 'user',
        status: user.status || 'active',
        dni: user.dni || '',
        subscription: user.subscription || {
          plan: 'basic',
          validUntil: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000).toISOString()
        }
      });
    }
  }, [user, mode]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      if (mode === 'edit' && user) {
        // Update existing user
        const userRef = doc(db, 'users', user.id);
        const updateData = {
          name: formData.name,
          phone: formData.phone,
          company: formData.company,
          role: formData.role,
          status: formData.status,
          dni: formData.dni,
          subscription: formData.subscription,
          updatedAt: new Date().toISOString()
        };

        await updateDoc(userRef, updateData);
        onSuccess({ ...user, ...updateData });
      } else {
        // Create new user
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          formData.email,
          formData.password
        );

        // Send password reset email
        await sendPasswordResetEmail(auth, formData.email);

        const newUser: UserType = {
          id: userCredential.user.uid,
          email: formData.email,
          name: formData.name,
          phone: formData.phone,
          company: formData.company,
          role: formData.role,
          status: formData.status,
          dni: formData.dni,
          subscription: formData.subscription,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          lastLoginAt: new Date().toISOString()
        };

        await updateDoc(doc(db, 'users', userCredential.user.uid), newUser);
        onSuccess(newUser);
      }

      onClose();
    } catch (error) {
      console.error('Error saving user:', error);
      setError(error instanceof Error ? error.message : 'Error al guardar el usuario');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30 backdrop-blur-sm" aria-hidden="true" />
      
      <div className="fixed inset-0 flex items-start justify-center p-4 overflow-y-auto">
        <Dialog.Panel className="relative mx-auto max-w-2xl w-full bg-white rounded-xl shadow-xl my-8">
          <form onSubmit={handleSubmit} className="max-h-[calc(100vh-4rem)] overflow-y-auto">
            <div className="sticky top-0 z-10 flex justify-between items-center p-6 border-b border-gray-200 bg-white rounded-t-xl">
              <div>
                <Dialog.Title className="text-xl font-semibold text-gray-900">
                  {mode === 'create' ? 'Crear Usuario' : 'Editar Usuario'}
                </Dialog.Title>
                <p className="mt-1 text-sm text-gray-500">
                  {mode === 'create' 
                    ? 'Ingresa los datos del nuevo usuario' 
                    : 'Modifica los datos del usuario'}
                </p>
              </div>
              <button
                type="button"
                onClick={onClose}
                className="text-gray-400 hover:text-gray-500 p-2 rounded-lg hover:bg-gray-100 transition-colors"
              >
                <X className="h-5 w-5" />
              </button>
            </div>

            {error && (
              <div className="mx-6 mt-6 p-4 bg-red-50 rounded-lg border border-red-200">
                <p className="text-sm text-red-600">{error}</p>
              </div>
            )}

            <div className="p-6 space-y-6">
              {/* Información Personal */}
              <div className="bg-blue-50/50 rounded-xl p-6 border border-blue-100">
                <div className="flex items-center space-x-3 mb-4">
                  <div className="p-2 bg-blue-100 rounded-lg">
                    <User className="h-5 w-5 text-blue-600" />
                  </div>
                  <h3 className="font-semibold text-blue-900">Información Personal</h3>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Nombre completo
                    </label>
                    <input
                      type="text"
                      value={formData.name}
                      onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                      className="w-full rounded-lg border-gray-300 focus:border-blue-500 focus:ring-blue-500"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      DNI/NIE
                    </label>
                    <input
                      type="text"
                      value={formData.dni}
                      onChange={(e) => setFormData(prev => ({ ...prev, dni: e.target.value }))}
                      className="w-full rounded-lg border-gray-300 focus:border-blue-500 focus:ring-blue-500"
                    />
                  </div>
                </div>
              </div>

              {/* Información de Contacto */}
              <div className="bg-purple-50/50 rounded-xl p-6 border border-purple-100">
                <div className="flex items-center space-x-3 mb-4">
                  <div className="p-2 bg-purple-100 rounded-lg">
                    <Mail className="h-5 w-5 text-purple-600" />
                  </div>
                  <h3 className="font-semibold text-purple-900">Información de Contacto</h3>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {mode === 'create' && (
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Email
                      </label>
                      <input
                        type="email"
                        value={formData.email}
                        onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
                        className="w-full rounded-lg border-gray-300 focus:border-purple-500 focus:ring-purple-500"
                        required={mode === 'create'}
                      />
                    </div>
                  )}
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Teléfono
                    </label>
                    <input
                      type="tel"
                      value={formData.phone}
                      onChange={(e) => setFormData(prev => ({ ...prev, phone: e.target.value }))}
                      className="w-full rounded-lg border-gray-300 focus:border-purple-500 focus:ring-purple-500"
                    />
                  </div>
                </div>
              </div>

              {/* Contraseña (solo para nuevo usuario) */}
              {mode === 'create' && (
                <div className="bg-rose-50/50 rounded-xl p-6 border border-rose-100">
                  <div className="flex items-center space-x-3 mb-4">
                    <div className="p-2 bg-rose-100 rounded-lg">
                      <Shield className="h-5 w-5 text-rose-600" />
                    </div>
                    <h3 className="font-semibold text-rose-900">Contraseña Temporal</h3>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Contraseña
                    </label>
                    <input
                      type="password"
                      value={formData.password}
                      onChange={(e) => setFormData(prev => ({ ...prev, password: e.target.value }))}
                      className="w-full rounded-lg border-gray-300 focus:border-rose-500 focus:ring-rose-500"
                      required={mode === 'create'}
                      minLength={6}
                    />
                    <p className="mt-1 text-sm text-gray-500">
                      Se enviará un email al usuario para que cambie su contraseña
                    </p>
                  </div>
                </div>
              )}

              {/* Información Empresarial */}
              <div className="bg-emerald-50/50 rounded-xl p-6 border border-emerald-100">
                <div className="flex items-center space-x-3 mb-4">
                  <div className="p-2 bg-emerald-100 rounded-lg">
                    <Building className="h-5 w-5 text-emerald-600" />
                  </div>
                  <h3 className="font-semibold text-emerald-900">Información Empresarial</h3>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Empresa
                  </label>
                  <input
                    type="text"
                    value={formData.company}
                    onChange={(e) => setFormData(prev => ({ ...prev, company: e.target.value }))}
                    className="w-full rounded-lg border-gray-300 focus:border-emerald-500 focus:ring-emerald-500"
                  />
                </div>
              </div>

              {/* Suscripción y Permisos */}
              <div className="bg-amber-50/50 rounded-xl p-6 border border-amber-100">
                <div className="flex items-center space-x-3 mb-4">
                  <div className="p-2 bg-amber-100 rounded-lg">
                    <Shield className="h-5 w-5 text-amber-600" />
                  </div>
                  <h3 className="font-semibold text-amber-900">Suscripción y Permisos</h3>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Válido hasta
                    </label>
                    <input
                      type="date"
                      value={formData.subscription.validUntil.split('T')[0]}
                      onChange={(e) => setFormData(prev => ({
                        ...prev,
                        subscription: { 
                          ...prev.subscription, 
                          validUntil: new Date(e.target.value).toISOString() 
                        }
                      }))}
                      className="w-full rounded-lg border-gray-300 focus:border-amber-500 focus:ring-amber-500"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Rol
                    </label>
                    <select
                      value={formData.role}
                      onChange={(e) => setFormData(prev => ({ ...prev, role: e.target.value as 'user' | 'admin' }))}
                      className="w-full rounded-lg border-gray-300 focus:border-amber-500 focus:ring-amber-500"
                    >
                      <option value="user">Usuario</option>
                      <option value="admin">Administrador</option>
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Estado
                    </label>
                    <select
                      value={formData.status}
                      onChange={(e) => setFormData(prev => ({ ...prev, status: e.target.value as 'active' | 'suspended' }))}
                      className="w-full rounded-lg border-gray-300 focus:border-amber-500 focus:ring-amber-500"
                    >
                      <option value="active">Activo</option>
                      <option value="suspended">Suspendido</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="sticky bottom-0 flex justify-end space-x-3 p-6 border-t border-gray-200 bg-white rounded-b-xl">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 border border-gray-300 rounded-lg text-sm font-medium text-gray-700 hover:bg-gray-50 transition-colors"
                disabled={loading}
              >
                Cancelar
              </button>
              <button
                type="submit"
                disabled={loading}
                className="px-4 py-2 bg-blue-600 text-white rounded-lg text-sm font-medium hover:bg-blue-700 transition-colors flex items-center"
              >
                {loading ? (
                  <>
                    <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                    Procesando...
                  </>
                ) : (
                  mode === 'create' ? 'Crear Usuario' : 'Guardar Cambios'
                )}
              </button>
            </div>
          </form>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}