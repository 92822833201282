import React from 'react';
import { 
  Clock, 
  Calendar, 
  MapPin, 
  Award, 
  Plane,
  Sun,
  Moon,
  Cloud,
  CloudRain,
  Users,
  Target,
  BarChart2,
  Loader2,
  AlertCircle
} from 'lucide-react';
import { useStatistics } from '../hooks/useStatistics';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  ArcElement,
} from 'chart.js';
import { Bar, Line, Doughnut } from 'react-chartjs-2';

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  ArcElement
);

export function Statistics() {
  const { statistics, loading, error } = useStatistics();

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="flex flex-col items-center space-y-4">
          <Loader2 className="h-8 w-8 animate-spin text-blue-600" />
          <p className="text-gray-600">Cargando estadísticas...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-center space-y-4">
          <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-red-100">
            <AlertCircle className="h-8 w-8 text-red-600" />
          </div>
          <p className="text-red-600">{error}</p>
          <button 
            onClick={() => window.location.reload()}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
          >
            Reintentar
          </button>
        </div>
      </div>
    );
  }

  // Chart configurations
  const monthlyChartData = {
    labels: statistics.monthlyStats.map(stat => stat.month),
    datasets: [
      {
        label: 'Horas de Vuelo',
        data: statistics.monthlyStats.map(stat => stat.hours),
        borderColor: 'rgb(59, 130, 246)',
        backgroundColor: 'rgba(59, 130, 246, 0.5)',
        tension: 0.4
      }
    ]
  };

  const aircraftChartData = {
    labels: statistics.aircraftStats.map(stat => stat.type),
    datasets: [
      {
        label: 'Horas por Aeronave',
        data: statistics.aircraftStats.map(stat => stat.hours),
        backgroundColor: [
          'rgba(59, 130, 246, 0.8)',
          'rgba(16, 185, 129, 0.8)',
          'rgba(245, 158, 11, 0.8)',
          'rgba(139, 92, 246, 0.8)'
        ]
      }
    ]
  };

  const flightConditionsData = {
    labels: ['VFR', 'IFR', 'VLOS', 'EVLOS', 'BVLOS'],
    datasets: [
      {
        data: [
          statistics.flightConditions.vfr,
          statistics.flightConditions.ifr,
          statistics.flightConditions.vlos,
          statistics.flightConditions.evlos,
          statistics.flightConditions.bvlos
        ],
        backgroundColor: [
          'rgba(59, 130, 246, 0.8)',
          'rgba(16, 185, 129, 0.8)',
          'rgba(245, 158, 11, 0.8)',
          'rgba(139, 92, 246, 0.8)',
          'rgba(236, 72, 153, 0.8)'
        ]
      }
    ]
  };

  return (
    <div className="space-y-8">
      {/* Banner */}
      <div className="relative h-[300px] -mx-4 -mt-4 overflow-hidden rounded-xl">
        <img
          className="w-full h-full object-cover"
          src="https://images.unsplash.com/photo-1551288049-bebda4e38f71?auto=format&fit=crop&q=80"
          alt="Banner de estadísticas"
        />
        <div className="absolute inset-0 bg-gradient-to-r from-blue-900/90 to-blue-800/70"></div>
        <div className="absolute inset-0 flex items-center px-8">
          <div className="max-w-3xl">
            <h1 className="text-4xl font-bold text-white mb-4">
              Estadísticas de Vuelo
            </h1>
            <p className="text-xl text-blue-100">
              Análisis detallado de tu actividad como piloto
            </p>
          </div>
        </div>
      </div>

      {/* Main Stats */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <div className="bg-white rounded-xl p-6 border border-gray-200">
          <div className="flex items-center">
            <div className="inline-flex items-center justify-center w-12 h-12 rounded-lg bg-blue-50 text-blue-600">
              <Clock className="h-6 w-6" />
            </div>
            <div className="ml-4">
              <h3 className="text-2xl font-bold text-gray-900">
                {statistics.totalStats.hours.toFixed(1)}h
              </h3>
              <p className="text-sm text-gray-500">Horas Totales</p>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-xl p-6 border border-gray-200">
          <div className="flex items-center">
            <div className="inline-flex items-center justify-center w-12 h-12 rounded-lg bg-green-50 text-green-600">
              <Calendar className="h-6 w-6" />
            </div>
            <div className="ml-4">
              <h3 className="text-2xl font-bold text-gray-900">
                {statistics.totalStats.flights}
              </h3>
              <p className="text-sm text-gray-500">Vuelos Realizados</p>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-xl p-6 border border-gray-200">
          <div className="flex items-center">
            <div className="inline-flex items-center justify-center w-12 h-12 rounded-lg bg-purple-50 text-purple-600">
              <MapPin className="h-6 w-6" />
            </div>
            <div className="ml-4">
              <h3 className="text-2xl font-bold text-gray-900">
                {statistics.totalStats.airports}
              </h3>
              <p className="text-sm text-gray-500">Aeródromos Visitados</p>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-xl p-6 border border-gray-200">
          <div className="flex items-center">
            <div className="inline-flex items-center justify-center w-12 h-12 rounded-lg bg-amber-50 text-amber-600">
              <Plane className="h-6 w-6" />
            </div>
            <div className="ml-4">
              <h3 className="text-2xl font-bold text-gray-900">
                {statistics.totalStats.aircraft}
              </h3>
              <p className="text-sm text-gray-500">Aeronaves Utilizadas</p>
            </div>
          </div>
        </div>
      </div>

      {/* Charts */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Monthly Flight Hours */}
        <div className="bg-white rounded-xl p-6 border border-gray-200">
          <div className="flex items-center space-x-4 mb-6">
            <div className="h-12 w-12 bg-gradient-to-br from-blue-500 to-blue-600 rounded-lg flex items-center justify-center text-white">
              <BarChart2 className="h-6 w-6" />
            </div>
            <div>
              <h3 className="text-lg font-semibold text-gray-900">Horas por Mes</h3>
              <p className="text-sm text-gray-500">Últimos 12 meses</p>
            </div>
          </div>
          <div className="h-64">
            <Line
              data={monthlyChartData}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false
                  }
                }
              }}
            />
          </div>
        </div>

        {/* Aircraft Distribution */}
        <div className="bg-white rounded-xl p-6 border border-gray-200">
          <div className="flex items-center space-x-4 mb-6">
            <div className="h-12 w-12 bg-gradient-to-br from-green-500 to-green-600 rounded-lg flex items-center justify-center text-white">
              <Plane className="h-6 w-6" />
            </div>
            <div>
              <h3 className="text-lg font-semibold text-gray-900">Horas por Aeronave</h3>
              <p className="text-sm text-gray-500">Distribución total</p>
            </div>
          </div>
          <div className="h-64">
            <Doughnut
              data={aircraftChartData}
              options={{
                responsive: true,
                maintainAspectRatio: false
              }}
            />
          </div>
        </div>

        {/* Flight Conditions */}
        <div className="bg-white rounded-xl p-6 border border-gray-200">
          <div className="flex items-center space-x-4 mb-6">
            <div className="h-12 w-12 bg-gradient-to-br from-purple-500 to-purple-600 rounded-lg flex items-center justify-center text-white">
              <Cloud className="h-6 w-6" />
            </div>
            <div>
              <h3 className="text-lg font-semibold text-gray-900">Condiciones de Vuelo</h3>
              <p className="text-sm text-gray-500">Distribución por tipo</p>
            </div>
          </div>
          <div className="h-64">
            <Doughnut
              data={flightConditionsData}
              options={{
                responsive: true,
                maintainAspectRatio: false
              }}
            />
          </div>
        </div>

        {/* Most Visited Airports */}
        <div className="bg-white rounded-xl p-6 border border-gray-200">
          <div className="flex items-center space-x-4 mb-6">
            <div className="h-12 w-12 bg-gradient-to-br from-amber-500 to-amber-600 rounded-lg flex items-center justify-center text-white">
              <Target className="h-6 w-6" />
            </div>
            <div>
              <h3 className="text-lg font-semibold text-gray-900">Aeródromos Frecuentes</h3>
              <p className="text-sm text-gray-500">Top 5 más visitados</p>
            </div>
          </div>
          <div className="space-y-4">
            {statistics.airports.slice(0, 5).map((airport, index) => (
              <div key={airport.code} className="p-4 bg-amber-50 rounded-xl">
                <div className="flex justify-between items-center">
                  <div>
                    <span className="text-lg font-semibold text-amber-900">{airport.code}</span>
                    <p className="text-sm text-amber-600">{airport.name}</p>
                  </div>
                  <div className="text-right">
                    <span className="text-sm font-medium text-amber-900">{airport.visits} visitas</span>
                    <p className="text-xs text-amber-600">Última: {new Date(airport.lastVisit).toLocaleDateString()}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}