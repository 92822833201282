import React from 'react';
import { CreditCard, DollarSign } from 'lucide-react';

interface SubscriptionPlansProps {
  price: number;
  onUpdatePrice: (newPrice: number) => void;
}

export function SubscriptionPlans({ price, onUpdatePrice }: SubscriptionPlansProps) {
  const [isEditing, setIsEditing] = React.useState(false);
  const [newPrice, setNewPrice] = React.useState(price);

  const handleSave = () => {
    onUpdatePrice(newPrice);
    setIsEditing(false);
  };

  return (
    <div className="bg-white rounded-xl border border-gray-200 overflow-hidden">
      <div className="p-6 border-b border-gray-200">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <div className="p-2 bg-emerald-100 rounded-xl">
              <CreditCard className="h-6 w-6 text-emerald-600" />
            </div>
            <div>
              <h3 className="text-lg font-semibold text-gray-900">Plan de Suscripción</h3>
              <p className="text-sm text-gray-500">Gestión del precio anual</p>
            </div>
          </div>
        </div>
      </div>
      <div className="p-6">
        <div className="max-w-2xl mx-auto bg-white rounded-xl border border-gray-200 p-6">
          <div className="flex justify-between items-start mb-6">
            <div>
              <h4 className="text-lg font-semibold text-gray-900">Plan LogBook</h4>
              {isEditing ? (
                <div className="mt-2 flex items-center space-x-2">
                  <input
                    type="number"
                    value={newPrice}
                    onChange={(e) => setNewPrice(Number(e.target.value))}
                    className="w-32 px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    min="0"
                    step="0.01"
                  />
                  <span className="text-gray-500">€/año</span>
                </div>
              ) : (
                <p className="text-2xl font-bold text-gray-900 mt-2">
                  {price}€<span className="text-sm text-gray-500">/año</span>
                </p>
              )}
            </div>
            <div className="px-3 py-1 rounded-full text-sm font-medium bg-green-100 text-green-800">
              Activo
            </div>
          </div>

          <ul className="space-y-3 mb-6">
            <li className="flex items-center text-gray-600">
              <span className="w-2 h-2 rounded-full bg-emerald-400 mr-2"></span>
              Registro de vuelos ilimitado
            </li>
            <li className="flex items-center text-gray-600">
              <span className="w-2 h-2 rounded-full bg-emerald-400 mr-2"></span>
              Aeronaves ilimitadas
            </li>
            <li className="flex items-center text-gray-600">
              <span className="w-2 h-2 rounded-full bg-emerald-400 mr-2"></span>
              Estadísticas avanzadas
            </li>
            <li className="flex items-center text-gray-600">
              <span className="w-2 h-2 rounded-full bg-emerald-400 mr-2"></span>
              Soporte prioritario
            </li>
            <li className="flex items-center text-gray-600">
              <span className="w-2 h-2 rounded-full bg-emerald-400 mr-2"></span>
              Exportación de datos
            </li>
            <li className="flex items-center text-gray-600">
              <span className="w-2 h-2 rounded-full bg-emerald-400 mr-2"></span>
              Backup automático
            </li>
          </ul>

          {isEditing ? (
            <div className="flex space-x-3">
              <button
                onClick={handleSave}
                className="flex-1 px-4 py-2 bg-emerald-600 text-white rounded-lg text-sm font-medium hover:bg-emerald-700"
              >
                Guardar Cambios
              </button>
              <button
                onClick={() => {
                  setNewPrice(price);
                  setIsEditing(false);
                }}
                className="flex-1 px-4 py-2 border border-gray-300 rounded-lg text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Cancelar
              </button>
            </div>
          ) : (
            <button
              onClick={() => setIsEditing(true)}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              Modificar Precio
            </button>
          )}
        </div>
      </div>
    </div>
  );
}