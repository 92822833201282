import React from 'react';
import { Calendar, Clock, MapPin, Plane, ArrowUpDown } from 'lucide-react';

type SortField = 'date' | 'totalTime' | 'aircraftRegistration' | 'departureLocation';

interface FlightSortButtonsProps {
  sortField: SortField;
  onSort: (field: SortField) => void;
}

export function FlightSortButtons({ sortField, onSort }: FlightSortButtonsProps) {
  return (
    <div className="flex flex-wrap gap-2">
      <button
        onClick={() => onSort('date')}
        className={`inline-flex items-center px-3 py-1.5 rounded-lg text-sm ${
          sortField === 'date'
            ? 'bg-blue-100 text-blue-700'
            : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
        }`}
      >
        <Calendar className="h-4 w-4 mr-1" />
        Fecha
        {sortField === 'date' && (
          <ArrowUpDown className="h-3 w-3 ml-1" />
        )}
      </button>

      <button
        onClick={() => onSort('totalTime')}
        className={`inline-flex items-center px-3 py-1.5 rounded-lg text-sm ${
          sortField === 'totalTime'
            ? 'bg-blue-100 text-blue-700'
            : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
        }`}
      >
        <Clock className="h-4 w-4 mr-1" />
        Tiempo
        {sortField === 'totalTime' && (
          <ArrowUpDown className="h-3 w-3 ml-1" />
        )}
      </button>

      <button
        onClick={() => onSort('aircraftRegistration')}
        className={`inline-flex items-center px-3 py-1.5 rounded-lg text-sm ${
          sortField === 'aircraftRegistration'
            ? 'bg-blue-100 text-blue-700'
            : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
        }`}
      >
        <Plane className="h-4 w-4 mr-1" />
        Aeronave
        {sortField === 'aircraftRegistration' && (
          <ArrowUpDown className="h-3 w-3 ml-1" />
        )}
      </button>

      <button
        onClick={() => onSort('departureLocation')}
        className={`inline-flex items-center px-3 py-1.5 rounded-lg text-sm ${
          sortField === 'departureLocation'
            ? 'bg-blue-100 text-blue-700'
            : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
        }`}
      >
        <MapPin className="h-4 w-4 mr-1" />
        Origen
        {sortField === 'departureLocation' && (
          <ArrowUpDown className="h-3 w-3 ml-1" />
        )}
      </button>
    </div>
  );
}