import { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';
import type { Aircraft } from '../types/aircraft';

export function useAircraft() {
  const [aircraft, setAircraft] = useState<Aircraft[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { user } = useAuth();

  useEffect(() => {
    const fetchAircraft = async () => {
      if (!user?.uid) {
        setLoading(false);
        return;
      }

      try {
        const aircraftRef = collection(db, 'aircraft');
        const aircraftQuery = query(
          aircraftRef, 
          where('userId', '==', user.uid)
        );
        
        const snapshot = await getDocs(aircraftQuery);
        const aircraftList: Aircraft[] = [];
        
        snapshot.forEach((doc) => {
          aircraftList.push({ 
            id: doc.id, 
            ...doc.data() 
          } as Aircraft);
        });

        setAircraft(aircraftList);
        setError(null);
      } catch (err) {
        console.error('Error fetching aircraft:', err);
        setError('Error al cargar las aeronaves');
      } finally {
        setLoading(false);
      }
    };

    fetchAircraft();
  }, [user?.uid]);

  return { aircraft, loading, error };
}