import { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { collection, query, where, getDocs, orderBy } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Flight } from '../types/flight';
import { format, subMonths, startOfMonth, endOfMonth } from 'date-fns';

interface MonthlyStats {
  month: string;
  hours: number;
  flights: number;
}

interface AircraftStats {
  type: string;
  hours: number;
  flights: number;
}

interface AirportVisit {
  code: string;
  name: string;
  visits: number;
  lastVisit: string;
}

interface Statistics {
  monthlyStats: MonthlyStats[];
  aircraftStats: AircraftStats[];
  flightConditions: {
    vfr: number;
    ifr: number;
    vlos: number;
    evlos: number;
    bvlos: number;
    day: number;
    night: number;
  };
  airports: AirportVisit[];
  totalStats: {
    hours: number;
    flights: number;
    airports: number;
    aircraft: number;
  };
}

const defaultStats: Statistics = {
  monthlyStats: [],
  aircraftStats: [],
  flightConditions: {
    vfr: 0,
    ifr: 0,
    vlos: 0,
    evlos: 0,
    bvlos: 0,
    day: 0,
    night: 0
  },
  airports: [],
  totalStats: {
    hours: 0,
    flights: 0,
    airports: 0,
    aircraft: 0
  }
};

export function useStatistics() {
  const { user } = useAuth();
  const [statistics, setStatistics] = useState<Statistics>(defaultStats);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function fetchStatistics() {
      if (!user?.uid) {
        setLoading(false);
        return;
      }

      try {
        // Fetch user's flights
        const flightsRef = collection(db, `users/${user.uid}/flights`);
        const flightsQuery = query(
          flightsRef,
          orderBy('date', 'desc')
        );
        
        const flightsSnapshot = await getDocs(flightsQuery);
        const flights: Flight[] = [];
        flightsSnapshot.forEach((doc) => {
          flights.push({ id: doc.id, ...doc.data() } as Flight);
        });

        // Calculate monthly stats for the last 12 months
        const monthlyStats: MonthlyStats[] = [];
        for (let i = 0; i < 12; i++) {
          const date = subMonths(new Date(), i);
          const monthStart = startOfMonth(date);
          const monthEnd = endOfMonth(date);
          
          const monthFlights = flights.filter(f => {
            const flightDate = new Date(f.date);
            return flightDate >= monthStart && flightDate <= monthEnd;
          });

          const monthlyHours = monthFlights.reduce((acc, flight) => {
            const [hours, minutes] = flight.totalTime.split(':').map(Number);
            return acc + hours + (minutes / 60);
          }, 0);

          monthlyStats.unshift({
            month: format(date, 'MMM yyyy'),
            hours: monthlyHours,
            flights: monthFlights.length
          });
        }

        // Calculate aircraft stats
        const aircraftMap = new Map<string, AircraftStats>();
        flights.forEach(flight => {
          const stats = aircraftMap.get(flight.aircraftType) || {
            type: flight.aircraftType,
            hours: 0,
            flights: 0
          };

          const [hours, minutes] = flight.totalTime.split(':').map(Number);
          stats.hours += hours + (minutes / 60);
          stats.flights += 1;
          aircraftMap.set(flight.aircraftType, stats);
        });

        // Calculate flight conditions
        const conditions = flights.reduce((acc, flight) => {
          acc[flight.flightConditions.toLowerCase()] = (acc[flight.flightConditions.toLowerCase()] || 0) + 1;
          acc[flight.timeOfDay.toLowerCase()] = (acc[flight.timeOfDay.toLowerCase()] || 0) + 1;
          return acc;
        }, {} as Record<string, number>);

        // Calculate airport visits correctly
        const airportVisits = new Map<string, { visits: number; lastVisit: string }>();
        
        flights.forEach(flight => {
          // Process departure airport
          const depStats = airportVisits.get(flight.departureLocation) || { visits: 0, lastVisit: flight.date };
          depStats.visits += 1;
          if (new Date(flight.date) > new Date(depStats.lastVisit)) {
            depStats.lastVisit = flight.date;
          }
          airportVisits.set(flight.departureLocation, depStats);

          // Process arrival airport (only if different from departure)
          if (flight.arrivalLocation !== flight.departureLocation) {
            const arrStats = airportVisits.get(flight.arrivalLocation) || { visits: 0, lastVisit: flight.date };
            arrStats.visits += 1;
            if (new Date(flight.date) > new Date(arrStats.lastVisit)) {
              arrStats.lastVisit = flight.date;
            }
            airportVisits.set(flight.arrivalLocation, arrStats);
          }
        });

        const airports = Array.from(airportVisits.entries())
          .map(([code, stats]) => ({
            code,
            name: code,
            visits: stats.visits,
            lastVisit: stats.lastVisit
          }))
          .sort((a, b) => b.visits - a.visits);

        // Calculate total hours correctly
        const totalHours = flights.reduce((acc, flight) => {
          const [hours, minutes] = flight.totalTime.split(':').map(Number);
          return acc + hours + (minutes / 60);
        }, 0);

        setStatistics({
          monthlyStats,
          aircraftStats: Array.from(aircraftMap.values()),
          flightConditions: {
            vfr: conditions.vfr || 0,
            ifr: conditions.ifr || 0,
            vlos: conditions.vlos || 0,
            evlos: conditions.evlos || 0,
            bvlos: conditions.bvlos || 0,
            day: conditions.day || 0,
            night: conditions.night || 0
          },
          airports,
          totalStats: {
            hours: totalHours,
            flights: flights.length,
            airports: airportVisits.size,
            aircraft: new Set(flights.map(f => f.aircraftRegistration)).size
          }
        });

        setError(null);
      } catch (error) {
        console.error('Error fetching statistics:', error);
        setError('Error al cargar las estadísticas. Por favor, intenta de nuevo.');
        setStatistics(defaultStats);
      } finally {
        setLoading(false);
      }
    }

    fetchStatistics();
  }, [user?.uid]);

  return { statistics, loading, error };
}