import React from 'react';
import { Search } from 'lucide-react';
import type { Flight } from '../../types/flight';

interface FlightFiltersProps {
  searchTerm: string;
  onSearchChange: (value: string) => void;
  filters: {
    aircraftType: string;
    flightConditions: string;
    timeOfDay: string;
  };
  onFilterChange: (name: string, value: string) => void;
  flights: Flight[];
}

export function FlightFilters({ 
  searchTerm, 
  onSearchChange, 
  filters, 
  onFilterChange,
  flights 
}: FlightFiltersProps) {
  const uniqueAircraftTypes = [...new Set(flights.map(f => f.aircraftType))];
  const uniqueConditions = [...new Set(flights.map(f => f.flightConditions))];

  return (
    <div className="flex flex-col sm:flex-row gap-4">
      {/* Search */}
      <div className="flex-1">
        <div className="relative">
          <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-400" />
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => onSearchChange(e.target.value)}
            placeholder="Buscar por matrícula, origen o destino..."
            className="w-full pl-9 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
      </div>

      {/* Filters */}
      <div className="flex gap-4">
        <select
          value={filters.aircraftType}
          onChange={(e) => onFilterChange('aircraftType', e.target.value)}
          className="border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
        >
          <option value="">Tipo de Aeronave</option>
          {uniqueAircraftTypes.map(type => (
            <option key={type} value={type}>{type}</option>
          ))}
        </select>

        <select
          value={filters.flightConditions}
          onChange={(e) => onFilterChange('flightConditions', e.target.value)}
          className="border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
        >
          <option value="">Condiciones</option>
          {uniqueConditions.map(condition => (
            <option key={condition} value={condition}>{condition}</option>
          ))}
        </select>

        <select
          value={filters.timeOfDay}
          onChange={(e) => onFilterChange('timeOfDay', e.target.value)}
          className="border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
        >
          <option value="">Momento del Día</option>
          <option value="DAY">Día</option>
          <option value="NIGHT">Noche</option>
        </select>
      </div>
    </div>
  );
}