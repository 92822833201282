import React, { useState } from 'react';
import { User } from '../../types/user';
import { Loader2, AlertCircle } from 'lucide-react';
import { Dialog } from '@headlessui/react';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

interface ProfileInfoProps {
  user: User;
  isEditing: boolean;
  saving: boolean;
  formData: {
    name: string;
    phone: string;
    company: string;
  };
  onFormChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (e: React.FormEvent) => void;
  onCancel: () => void;
  onEdit: () => void;
}

export function ProfileInfo({
  user,
  isEditing,
  saving,
  formData,
  onFormChange,
  onSubmit,
  onCancel,
  onEdit
}: ProfileInfoProps) {
  const [showSuspendModal, setShowSuspendModal] = useState(false);
  const [showFinalConfirmation, setShowFinalConfirmation] = useState(false);
  const [suspending, setSuspending] = useState(false);
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleSuspendAccount = async () => {
    if (!user.id) return;
    
    setSuspending(true);
    try {
      const userRef = doc(db, 'users', user.id);
      await updateDoc(userRef, {
        status: 'suspended',
        updatedAt: new Date().toISOString()
      });
      
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Error suspending account:', error);
      alert('Error al suspender la cuenta. Por favor, inténtalo de nuevo.');
    } finally {
      setSuspending(false);
    }
  };

  return (
    <>
      <div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
        <div className="p-6 border-b border-gray-200">
          <div className="flex justify-between items-center">
            <h2 className="text-lg font-semibold text-gray-900">Información Personal</h2>
            {!isEditing && (
              <button
                onClick={onEdit}
                className="px-4 py-2 bg-blue-600 text-white rounded-lg text-sm font-medium hover:bg-blue-700 transition-colors"
              >
                Editar Perfil
              </button>
            )}
          </div>
        </div>

        <form onSubmit={onSubmit} className="p-6 space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Nombre completo
            </label>
            {isEditing ? (
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={onFormChange}
                className="w-full rounded-lg border-gray-300 focus:border-blue-500 focus:ring-blue-500"
                required
              />
            ) : (
              <p className="text-gray-900">{user.name || 'No especificado'}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Email
            </label>
            <p className="text-gray-900">{user.email}</p>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Teléfono
            </label>
            {isEditing ? (
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={onFormChange}
                className="w-full rounded-lg border-gray-300 focus:border-blue-500 focus:ring-blue-500"
              />
            ) : (
              <p className="text-gray-900">{user.phone || 'No especificado'}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Empresa
            </label>
            {isEditing ? (
              <input
                type="text"
                name="company"
                value={formData.company}
                onChange={onFormChange}
                className="w-full rounded-lg border-gray-300 focus:border-blue-500 focus:ring-blue-500"
              />
            ) : (
              <p className="text-gray-900">{user.company || 'No especificado'}</p>
            )}
          </div>

          {isEditing && (
            <div className="flex justify-end space-x-4 pt-4">
              <button
                type="button"
                onClick={onCancel}
                className="px-4 py-2 border border-gray-300 rounded-lg text-sm font-medium text-gray-700 hover:bg-gray-50"
                disabled={saving}
              >
                Cancelar
              </button>
              <button
                type="submit"
                disabled={saving}
                className="px-4 py-2 bg-blue-600 text-white rounded-lg text-sm font-medium hover:bg-blue-700 flex items-center"
              >
                {saving ? (
                  <>
                    <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                    Guardando...
                  </>
                ) : (
                  'Guardar Cambios'
                )}
              </button>
            </div>
          )}
        </form>

        {/* Account Suspension Section */}
        <div className="px-6 pb-6">
          <div className="mt-6 pt-6 border-t border-gray-200">
            <div className="rounded-lg bg-red-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <AlertCircle className="h-5 w-5 text-red-400" />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">Zona de Peligro</h3>
                  <div className="mt-2 text-sm text-red-700">
                    <p>
                      Si das de baja tu cuenta, perderás acceso a todos tus datos y funcionalidades.
                    </p>
                  </div>
                  <div className="mt-4">
                    <button
                      type="button"
                      onClick={() => setShowSuspendModal(true)}
                      className="inline-flex items-center px-3 py-2 border border-red-600 text-sm leading-4 font-medium rounded-md text-red-600 bg-red-50 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    >
                      Dar de baja mi cuenta
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Initial Confirmation Modal */}
      <Dialog open={showSuspendModal} onClose={() => setShowSuspendModal(false)} className="relative z-50">
        <div className="fixed inset-0 bg-black/30 backdrop-blur-sm" aria-hidden="true" />
        
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="mx-auto max-w-md w-full bg-white rounded-xl shadow-xl p-6">
            <Dialog.Title className="text-lg font-medium text-gray-900 mb-4">
              ¿Estás seguro de que quieres dar de baja tu cuenta?
            </Dialog.Title>
            
            <p className="text-sm text-gray-500 mb-6">
              Esta acción suspenderá tu cuenta y perderás acceso a todas las funcionalidades.
              Podrás reactivarla más adelante contactando con soporte.
            </p>

            <div className="flex justify-end space-x-3">
              <button
                type="button"
                onClick={() => setShowSuspendModal(false)}
                className="px-4 py-2 border border-gray-300 rounded-lg text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Cancelar
              </button>
              <button
                type="button"
                onClick={() => {
                  setShowSuspendModal(false);
                  setShowFinalConfirmation(true);
                }}
                className="px-4 py-2 bg-red-600 text-white rounded-lg text-sm font-medium hover:bg-red-700"
              >
                Continuar
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>

      {/* Final Confirmation Modal */}
      <Dialog open={showFinalConfirmation} onClose={() => setShowFinalConfirmation(false)} className="relative z-50">
        <div className="fixed inset-0 bg-black/30 backdrop-blur-sm" aria-hidden="true" />
        
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="mx-auto max-w-md w-full bg-white rounded-xl shadow-xl p-6">
            <Dialog.Title className="text-lg font-medium text-red-600 mb-4">
              Confirmación Final
            </Dialog.Title>
            
            <p className="text-sm text-gray-500 mb-6">
              ¿Realmente deseas dar de baja tu cuenta? Esta acción cerrará tu sesión inmediatamente.
            </p>

            <div className="flex justify-end space-x-3">
              <button
                type="button"
                onClick={() => setShowFinalConfirmation(false)}
                className="px-4 py-2 border border-gray-300 rounded-lg text-sm font-medium text-gray-700 hover:bg-gray-50"
                disabled={suspending}
              >
                Cancelar
              </button>
              <button
                type="button"
                onClick={handleSuspendAccount}
                disabled={suspending}
                className="px-4 py-2 bg-red-600 text-white rounded-lg text-sm font-medium hover:bg-red-700 flex items-center"
              >
                {suspending ? (
                  <>
                    <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                    Procesando...
                  </>
                ) : (
                  'Sí, dar de baja mi cuenta'
                )}
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
}