import React from 'react';
import { Link } from 'react-router-dom';
import { Plane } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';

export function Footer() {
  const { user } = useAuth();

  return (
    <footer className="bg-white border-t border-gray-200">
      <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
        {!user ? (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 pb-8">
              {/* LogBook Info */}
              <div className="flex items-start space-x-3">
                <div className="w-10 h-10 bg-blue-600 rounded-xl flex items-center justify-center flex-shrink-0">
                  <Plane className="w-6 h-6 text-white transform -rotate-45" />
                </div>
                <div>
                  <h3 className="text-lg font-bold text-gray-900">LogBook</h3>
                  <p className="mt-2 text-sm text-gray-500 max-w-md">
                    La plataforma profesional para el registro de vuelos y gestión de operaciones aéreas.
                    Desarrollada por expertos del sector aeronáutico.
                  </p>
                </div>
              </div>

              {/* Quick Links */}
              <div className="grid grid-cols-2 gap-8">
                <div>
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                    Plataforma
                  </h3>
                  <ul className="mt-4 space-y-3">
                    <li>
                      <Link to="/login" className="text-base text-gray-500 hover:text-gray-900">
                        Iniciar Sesión
                      </Link>
                    </li>
                    <li>
                      <Link to="/registro" className="text-base text-gray-500 hover:text-gray-900">
                        Crear Cuenta
                      </Link>
                    </li>
                  </ul>
                </div>
                <div>
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                    Legal
                  </h3>
                  <ul className="mt-4 space-y-3">
                    <li>
                      <a 
                        href="https://www.ciudalia.com/aviso-legal" 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="text-base text-gray-500 hover:text-gray-900"
                      >
                        Privacidad
                      </a>
                    </li>
                    <li>
                      <a 
                        href="https://www.ciudalia.com/aviso-legal" 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="text-base text-gray-500 hover:text-gray-900"
                      >
                        Términos
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="pt-8 border-t border-gray-200">
              <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
                <div className="flex items-center space-x-2">
                  <span className="text-gray-500">Desarrollado por</span>
                  <a 
                    href="https://www.ciudalia.com" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="text-blue-600 font-semibold hover:text-blue-700 transition-colors"
                  >
                    Ciudalia Networks
                  </a>
                </div>
                <p className="text-sm text-gray-500">
                  © {new Date().getFullYear()} LogBook. Todos los derechos reservados.
                </p>
              </div>
            </div>
          </>
        ) : (
          <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
            <div className="flex items-center space-x-2">
              <span className="text-gray-500">Desarrollado por</span>
              <a 
                href="https://www.ciudalia.com" 
                target="_blank" 
                rel="noopener noreferrer"
                className="text-blue-600 font-semibold hover:text-blue-700 transition-colors"
              >
                Ciudalia Networks
              </a>
            </div>
            <p className="text-sm text-gray-500">
              © {new Date().getFullYear()} LogBook. Todos los derechos reservados.
            </p>
          </div>
        )}
      </div>
    </footer>
  );
}