import React, { useState, useEffect } from 'react';
import { collection, query, getDocs, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { User } from '../types/user';
import { Flight } from '../types/flight';
import { UsersTable } from '../components/admin/UsersTable';
import { UserModal } from '../components/admin/UserModal';
import { AdminStats } from '../components/admin/AdminStats';
import { SubscriptionPlans } from '../components/admin/SubscriptionPlans';
import { AdminFlights } from '../components/admin/AdminFlights';
import { Users, Plus, Loader2, AlertCircle } from 'lucide-react';

export function Admin() {
  const [users, setUsers] = useState<User[]>([]);
  const [flights, setFlights] = useState<Flight[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showUserModal, setShowUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [stats, setStats] = useState({
    activeUsers: 0,
    totalUsers: 0,
    totalFlights: 0,
    totalAircraft: 0,
    monthlyRevenue: 0,
    yearlyRevenue: 0,
    activeSubscriptions: 0
  });

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    if (users.length > 0) {
      fetchFlights();
    }
  }, [users]);

  useEffect(() => {
    calculateStats();
  }, [users, flights]);

  const fetchUsers = async () => {
    try {
      const usersRef = collection(db, 'users');
      const snapshot = await getDocs(usersRef);
      const usersList: User[] = [];
      
      snapshot.forEach((doc) => {
        // Excluir el documento _metadata si existe
        if (doc.id !== '_metadata') {
          usersList.push({ id: doc.id, ...doc.data() } as User);
        }
      });
      
      setUsers(usersList);
      setError(null);
    } catch (err) {
      console.error('Error fetching users:', err);
      setError('Error al cargar los usuarios');
    } finally {
      setLoading(false);
    }
  };

  const fetchFlights = async () => {
    try {
      const allFlights: Flight[] = [];
      
      // Para cada usuario, obtener sus vuelos
      for (const user of users) {
        if (!user.id || user.id === '_metadata') continue;

        const flightsRef = collection(db, `users/${user.id}/flights`);
        const flightsSnapshot = await getDocs(flightsRef);
        
        flightsSnapshot.forEach((flightDoc) => {
          if (flightDoc.id !== '_metadata') {
            allFlights.push({
              id: flightDoc.id,
              ...flightDoc.data(),
              userId: user.id,
              userName: user.name || user.email
            } as Flight);
          }
        });
      }
      
      // Ordenar vuelos por fecha, más recientes primero
      allFlights.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
      
      setFlights(allFlights);
    } catch (err) {
      console.error('Error fetching flights:', err);
      setError('Error al cargar los vuelos');
    }
  };

  const calculateStats = () => {
    // Filtrar usuarios excluyendo metadata
    const validUsers = users.filter(u => u.id !== '_metadata');
    const activeUsers = validUsers.filter(u => u.status === 'active').length;

    // Obtener vuelos válidos
    const validFlights = flights.filter(f => f.id !== '_metadata');

    // Obtener aeronaves únicas
    const uniqueAircraft = new Set(validFlights.map(f => f.aircraftRegistration)).size;

    // Calcular suscripciones activas
    const activeSubscriptions = validUsers.filter(u => 
      u.subscription?.status === 'active'
    ).length;

    // Calcular ingresos (60€/año por usuario activo)
    const monthlyRevenue = activeUsers * 5; // 60€/12 = 5€ por mes
    const yearlyRevenue = monthlyRevenue * 12;

    setStats({
      activeUsers,
      totalUsers: validUsers.length,
      totalFlights: validFlights.length,
      totalAircraft: uniqueAircraft,
      monthlyRevenue,
      yearlyRevenue,
      activeSubscriptions
    });
  };

  const handleUserUpdate = async (updatedUser: User) => {
    try {
      const userRef = doc(db, 'users', updatedUser.id);
      await updateDoc(userRef, {
        ...updatedUser,
        updatedAt: new Date().toISOString()
      });
      
      setUsers(prev => prev.map(u => 
        u.id === updatedUser.id ? updatedUser : u
      ));
      
      setSelectedUser(null);
      setShowUserModal(false);
    } catch (err) {
      console.error('Error updating user:', err);
      alert('Error al actualizar el usuario');
    }
  };

  const handleDeleteUser = async (userId: string) => {
    if (!window.confirm('¿Estás seguro de que deseas eliminar este usuario? Esta acción no se puede deshacer.')) {
      return;
    }

    try {
      await deleteDoc(doc(db, 'users', userId));
      setUsers(prev => prev.filter(u => u.id !== userId));
    } catch (err) {
      console.error('Error deleting user:', err);
      alert('Error al eliminar el usuario');
    }
  };

  const handleSuspendUser = async (userId: string) => {
    try {
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, {
        status: 'suspended',
        updatedAt: new Date().toISOString()
      });
      
      setUsers(prev => prev.map(u => 
        u.id === userId ? { ...u, status: 'suspended' } : u
      ));
    } catch (err) {
      console.error('Error suspending user:', err);
      alert('Error al suspender el usuario');
    }
  };

  const handleActivateUser = async (userId: string) => {
    try {
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, {
        status: 'active',
        updatedAt: new Date().toISOString()
      });
      
      setUsers(prev => prev.map(u => 
        u.id === userId ? { ...u, status: 'active' } : u
      ));
    } catch (err) {
      console.error('Error activating user:', err);
      alert('Error al activar el usuario');
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="flex flex-col items-center space-y-4">
          <Loader2 className="h-8 w-8 animate-spin text-blue-600" />
          <p className="text-gray-600">Cargando panel de administración...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-center space-y-4">
          <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-red-100">
            <AlertCircle className="h-8 w-8 text-red-600" />
          </div>
          <p className="text-red-600">{error}</p>
          <button 
            onClick={() => window.location.reload()}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
          >
            Reintentar
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      {/* Banner */}
      <div className="relative h-[300px] -mx-4 -mt-4 overflow-hidden rounded-xl">
        <img
          className="w-full h-full object-cover"
          src="https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?auto=format&fit=crop&q=80"
          alt="Admin dashboard"
        />
        <div className="absolute inset-0 bg-gradient-to-r from-blue-900/90 to-blue-800/70"></div>
        <div className="absolute inset-0 flex items-center px-8">
          <div className="max-w-3xl">
            <h1 className="text-4xl font-bold text-white mb-4">
              Panel de Administración
            </h1>
            <p className="text-xl text-blue-100 mb-8">
              Gestiona usuarios, suscripciones y configuración del sistema
            </p>
            <button
              onClick={() => {
                setSelectedUser(null);
                setShowUserModal(true);
              }}
              className="inline-flex items-center px-6 py-3 bg-white text-blue-900 text-base font-medium rounded-lg hover:bg-blue-50 transition-all duration-200 transform hover:scale-105 shadow-lg"
            >
              <Plus className="h-5 w-5 mr-2" />
              Crear Usuario
            </button>
          </div>
        </div>
      </div>

      {/* Stats */}
      <AdminStats stats={stats} />

      {/* Users List */}
      <div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
        <div className="p-6 border-b border-gray-200">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <div className="p-2 bg-blue-100 rounded-xl">
                <Users className="h-6 w-6 text-blue-600" />
              </div>
              <div>
                <h3 className="text-lg font-semibold text-gray-900">Usuarios</h3>
                <p className="text-sm text-gray-500">Gestión de usuarios del sistema</p>
              </div>
            </div>
            <button
              onClick={() => {
                setSelectedUser(null);
                setShowUserModal(true);
              }}
              className="px-4 py-2 bg-blue-600 text-white rounded-lg text-sm font-medium hover:bg-blue-700 flex items-center"
            >
              <Plus className="h-4 w-4 mr-2" />
              Crear Usuario
            </button>
          </div>
        </div>

        <UsersTable
          users={users.filter(u => u.id !== '_metadata')}
          onViewUser={(user) => {
            setSelectedUser(user);
            setShowUserModal(true);
          }}
          onDeleteUser={handleDeleteUser}
          onSuspendUser={handleSuspendUser}
          onActivateUser={handleActivateUser}
        />
      </div>

      {/* Flights List */}
      <AdminFlights flights={flights} users={users} />

      {/* Subscription Plans */}
      <SubscriptionPlans
        price={60}
        onUpdatePrice={(newPrice) => {
          // Handle price update
          console.log('New price:', newPrice);
        }}
      />

      {/* User Modal */}
      <UserModal
        isOpen={showUserModal}
        onClose={() => {
          setShowUserModal(false);
          setSelectedUser(null);
        }}
        user={selectedUser}
        mode={selectedUser ? 'edit' : 'create'}
        onSuccess={(user) => {
          if (selectedUser) {
            handleUserUpdate(user);
          } else {
            setUsers(prev => [...prev, user]);
          }
          setShowUserModal(false);
          setSelectedUser(null);
        }}
      />
    </div>
  );
}