import React, { createContext, useContext, useState, useEffect } from 'react';
import { db, initializeCollections } from '../lib/firebase';
import { useAuth } from './AuthContext';

interface FirebaseContextType {
  isInitialized: boolean;
  error: string | null;
}

const FirebaseContext = createContext<FirebaseContextType | null>(null);

export function FirebaseProvider({ children }: { children: React.ReactNode }) {
  const [isInitialized, setIsInitialized] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { user } = useAuth();

  useEffect(() => {
    let mounted = true;

    const initializeFirebase = async () => {
      if (!user?.uid) return;

      try {
        await initializeCollections(user.uid);
        if (mounted) {
          setIsInitialized(true);
          setError(null);
        }
      } catch (err) {
        console.error('Error initializing Firebase:', err);
        if (mounted) {
          setError('Error initializing the application. Please try again.');
          setIsInitialized(false);
        }
      }
    };

    initializeFirebase();

    return () => {
      mounted = false;
    };
  }, [user?.uid]);

  return (
    <FirebaseContext.Provider value={{ isInitialized, error }}>
      {children}
    </FirebaseContext.Provider>
  );
}

export function useFirebase() {
  const context = useContext(FirebaseContext);
  if (!context) {
    throw new Error('useFirebase must be used within a FirebaseProvider');
  }
  return context;
}