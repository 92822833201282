import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import type { Flight } from '../types/flight';
import type { User } from '../types/user';

export async function generatePDF(flights: Flight[], user: User, totalHours: number) {
  const doc = new jsPDF();
  
  // Add LogBook.es logo and header
  doc.setFontSize(24);
  doc.setTextColor(0, 87, 183); // Blue color
  doc.text('LogBook.es', 20, 20);
  
  doc.setFontSize(12);
  doc.setTextColor(128, 128, 128); // Gray color
  doc.text('Registro Profesional de Vuelos', 20, 30);

  // Add pilot information
  doc.setFontSize(11);
  doc.setTextColor(0, 0, 0);
  doc.text(`Piloto: ${user.name}`, 20, 45);
  doc.text(`Email: ${user.email}`, 20, 52);
  doc.text(`Total Horas: ${totalHours}h`, 20, 59);
  doc.text(`Fecha de emisión: ${new Date().toLocaleDateString()}`, 20, 66);

  // Create table with flight data
  const tableData = flights.map(flight => [
    flight.date,
    flight.departureLocation,
    flight.arrivalLocation,
    flight.aircraftRegistration,
    flight.totalTime,
    flight.flightConditions,
    flight.timeOfDay,
    flight.remarks || ''
  ]);

  doc.autoTable({
    startY: 75,
    head: [[
      'Fecha',
      'Origen',
      'Destino',
      'Aeronave',
      'Tiempo',
      'Condiciones',
      'Momento',
      'Observaciones'
    ]],
    body: tableData,
    theme: 'grid',
    styles: { fontSize: 8 },
    headStyles: { 
      fillColor: [0, 87, 183],
      textColor: 255,
      fontSize: 9,
      fontStyle: 'bold'
    },
    alternateRowStyles: { fillColor: [245, 247, 250] }
  });

  // Add footer
  const pageCount = doc.internal.getNumberOfPages();
  for (let i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.setFontSize(8);
    doc.setTextColor(128, 128, 128);
    doc.text(
      'LogBook.es - La plataforma profesional para el registro de vuelos',
      20,
      doc.internal.pageSize.height - 10
    );
    doc.text(
      `Página ${i} de ${pageCount}`,
      doc.internal.pageSize.width - 40,
      doc.internal.pageSize.height - 10
    );
  }

  // Save the PDF
  doc.save(`LogBook_${user.name}_${new Date().toISOString().split('T')[0]}.pdf`);
}