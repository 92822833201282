import React, { useState } from 'react';
import { format, isValid, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';
import { PlaneTakeoff, Search, Calendar, Pencil, Trash2, AlertCircle } from 'lucide-react';
import type { Flight } from '../../types/flight';
import type { User as UserType } from '../../types/user';
import { FlightForm } from '../flights/FlightForm';
import { doc, deleteDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';

interface AdminFlightsProps {
  flights: Flight[];
  users: UserType[];
}

export function AdminFlights({ flights, users }: AdminFlightsProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUser, setSelectedUser] = useState<string>('');
  const [dateRange, setDateRange] = useState({
    start: '',
    end: ''
  });
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedFlight, setSelectedFlight] = useState<Flight | null>(null);
  const [loading, setLoading] = useState(false);

  const formatDate = (dateString: string) => {
    try {
      const date = parseISO(dateString);
      if (!isValid(date)) {
        return 'Fecha inválida';
      }
      return format(date, 'dd MMM yyyy', { locale: es });
    } catch {
      return 'Fecha inválida';
    }
  };

  // Filter out metadata documents and apply user filters
  const filteredFlights = flights
    .filter(flight => flight.id !== '_metadata') // Exclude metadata documents
    .filter(flight => {
      const matchesSearch = searchTerm === '' || 
        flight.aircraftRegistration?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        flight.departureLocation?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        flight.arrivalLocation?.toLowerCase().includes(searchTerm.toLowerCase());

      const matchesUser = selectedUser === '' || flight.userId === selectedUser;

      const flightDate = flight.date ? parseISO(flight.date) : null;
      const startDate = dateRange.start ? parseISO(dateRange.start) : null;
      const endDate = dateRange.end ? parseISO(dateRange.end) : null;

      const matchesDate = 
        (!startDate || (flightDate && flightDate >= startDate)) &&
        (!endDate || (flightDate && flightDate <= endDate));

      return matchesSearch && matchesUser && matchesDate;
    })
    .sort((a, b) => {
      // Sort by date in descending order (most recent first)
      const dateA = parseISO(a.date);
      const dateB = parseISO(b.date);
      return dateB.getTime() - dateA.getTime();
    });

  const getUserName = (userId: string) => {
    const user = users.find(u => u.id === userId);
    return user?.name || user?.email || 'Usuario desconocido';
  };

  const handleEdit = async (updatedFlight: Flight) => {
    if (!selectedFlight) return;

    setLoading(true);
    try {
      const flightRef = doc(db, `users/${selectedFlight.userId}/flights`, selectedFlight.id);
      await updateDoc(flightRef, {
        ...updatedFlight,
        updatedAt: new Date().toISOString()
      });

      // Update local state
      const updatedFlights = flights.map(f => 
        f.id === selectedFlight.id ? { ...updatedFlight, id: selectedFlight.id } : f
      );
      
      setShowEditModal(false);
      setSelectedFlight(null);
      window.location.reload(); // Refresh to update the list
    } catch (error) {
      console.error('Error updating flight:', error);
      alert('Error al actualizar el vuelo');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (flight: Flight) => {
    if (!window.confirm('¿Estás seguro de que deseas eliminar este vuelo? Esta acción no se puede deshacer.')) {
      return;
    }

    setLoading(true);
    try {
      const flightRef = doc(db, `users/${flight.userId}/flights`, flight.id);
      await deleteDoc(flightRef);
      
      // Update local state
      const updatedFlights = flights.filter(f => f.id !== flight.id);
      window.location.reload(); // Refresh to update the list
    } catch (error) {
      console.error('Error deleting flight:', error);
      alert('Error al eliminar el vuelo');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
        <div className="p-6 border-b border-gray-200">
          <div className="flex items-center space-x-4">
            <div className="p-2 bg-green-100 rounded-xl">
              <PlaneTakeoff className="h-6 w-6 text-green-600" />
            </div>
            <div>
              <h3 className="text-lg font-semibold text-gray-900">Registro Global de Vuelos</h3>
              <p className="text-sm text-gray-500">Todos los vuelos registrados en el sistema</p>
            </div>
          </div>

          {/* Filters */}
          <div className="mt-6 grid grid-cols-1 md:grid-cols-4 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Buscar
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Search className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Matrícula, origen, destino..."
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Usuario
              </label>
              <select
                value={selectedUser}
                onChange={(e) => setSelectedUser(e.target.value)}
                className="block w-full border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="">Todos los usuarios</option>
                {users
                  .filter(user => user.id !== '_metadata')
                  .map(user => (
                    <option key={user.id} value={user.id}>
                      {user.name || user.email}
                    </option>
                  ))
                }
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Fecha Inicio
              </label>
              <input
                type="date"
                value={dateRange.start}
                onChange={(e) => setDateRange(prev => ({ ...prev, start: e.target.value }))}
                className="block w-full border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Fecha Fin
              </label>
              <input
                type="date"
                value={dateRange.end}
                onChange={(e) => setDateRange(prev => ({ ...prev, end: e.target.value }))}
                className="block w-full border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Fecha
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Usuario
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Aeronave
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Ruta
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Tiempo
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Condiciones
                </th>
                <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Acciones
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredFlights.map((flight) => (
                <tr key={`${flight.id}-${flight.userId}`} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {formatDate(flight.date)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {getUserName(flight.userId)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {flight.aircraftRegistration}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {flight.departureLocation} → {flight.arrivalLocation}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {flight.totalTime}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
                      {flight.flightConditions}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <div className="flex justify-end space-x-2">
                      <button
                        onClick={() => {
                          setSelectedFlight(flight);
                          setShowEditModal(true);
                        }}
                        className="text-blue-600 hover:text-blue-900 p-2 hover:bg-blue-50 rounded-lg transition-colors"
                        title="Editar vuelo"
                      >
                        <Pencil className="h-4 w-4" />
                      </button>
                      <button
                        onClick={() => handleDelete(flight)}
                        className="text-red-600 hover:text-red-900 p-2 hover:bg-red-50 rounded-lg transition-colors"
                        title="Eliminar vuelo"
                      >
                        <Trash2 className="h-4 w-4" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {filteredFlights.length === 0 && (
            <div className="text-center py-12">
              <p className="text-gray-500">No se encontraron vuelos con los filtros seleccionados</p>
            </div>
          )}
        </div>
      </div>

      {/* Edit Flight Modal */}
      {showEditModal && selectedFlight && (
        <div className="fixed inset-0 bg-black/30 backdrop-blur-sm flex items-center justify-center z-50">
          <div className="bg-white rounded-xl shadow-xl max-w-4xl w-full mx-4 max-h-[90vh] overflow-y-auto">
            <FlightForm
              onSubmit={handleEdit}
              onCancel={() => {
                setShowEditModal(false);
                setSelectedFlight(null);
              }}
              initialFlight={selectedFlight}
            />
          </div>
        </div>
      )}
    </>
  );
}