import ExcelJS from 'exceljs';
import { Flight } from '../types/flight';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

export async function exportToExcel(flights: Flight[], userName: string) {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Registro de Vuelos');

  // Add title
  worksheet.mergeCells('A1:I1');
  const titleCell = worksheet.getCell('A1');
  titleCell.value = 'Registro de Vuelos';
  titleCell.font = {
    size: 16,
    bold: true,
    color: { argb: '2563EB' }
  };
  titleCell.alignment = { horizontal: 'center' };

  // Add metadata
  worksheet.mergeCells('A2:I2');
  const metadataCell = worksheet.getCell('A2');
  metadataCell.value = `Piloto: ${userName} | Fecha de exportación: ${format(new Date(), 'dd/MM/yyyy', { locale: es })}`;
  metadataCell.font = { size: 11, color: { argb: '666666' } };
  metadataCell.alignment = { horizontal: 'center' };

  // Define columns
  worksheet.columns = [
    { header: 'Fecha', key: 'date', width: 12 },
    { header: 'Aeronave', key: 'aircraft', width: 15 },
    { header: 'Origen', key: 'departure', width: 12 },
    { header: 'Destino', key: 'arrival', width: 12 },
    { header: 'Tiempo', key: 'time', width: 10 },
    { header: 'Condiciones', key: 'conditions', width: 12 },
    { header: 'Momento', key: 'timeOfDay', width: 10 },
    { header: 'Rol', key: 'role', width: 15 },
    { header: 'Observaciones', key: 'remarks', width: 30 }
  ];

  // Style header row
  const headerRow = worksheet.getRow(4);
  headerRow.font = { bold: true, color: { argb: 'FFFFFF' } };
  headerRow.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: '2563EB' }
  };
  headerRow.alignment = { horizontal: 'center' };

  // Add data
  flights.forEach((flight) => {
    worksheet.addRow({
      date: format(new Date(flight.date), 'dd/MM/yyyy'),
      aircraft: flight.aircraftRegistration,
      departure: flight.departureLocation,
      arrival: flight.arrivalLocation,
      time: flight.totalTime,
      conditions: flight.flightConditions,
      timeOfDay: flight.timeOfDay === 'DAY' ? 'Día' : 'Noche',
      role: getPilotRoleText(flight.pilotRole),
      remarks: flight.remarks || ''
    });
  });

  // Style data rows
  worksheet.eachRow((row, rowNumber) => {
    if (rowNumber > 4) { // Skip header rows
      row.alignment = { horizontal: 'center', vertical: 'middle' };
      if (rowNumber % 2 === 0) {
        row.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'F9FAFB' }
        };
      }
    }
    row.eachCell((cell) => {
      cell.border = {
        top: { style: 'thin', color: { argb: 'E5E7EB' } },
        left: { style: 'thin', color: { argb: 'E5E7EB' } },
        bottom: { style: 'thin', color: { argb: 'E5E7EB' } },
        right: { style: 'thin', color: { argb: 'E5E7EB' } }
      };
    });
  });

  // Add totals
  const totalHours = flights.reduce((acc, flight) => {
    const [hours, minutes] = flight.totalTime.split(':').map(Number);
    return acc + hours + (minutes / 60);
  }, 0);

  const lastRow = worksheet.lastRow!.number + 2;
  worksheet.mergeCells(`A${lastRow}:C${lastRow}`);
  const totalCell = worksheet.getCell(`A${lastRow}`);
  totalCell.value = `Total Horas: ${totalHours.toFixed(1)}`;
  totalCell.font = { bold: true };

  // Generate buffer
  const buffer = await workbook.xlsx.writeBuffer();
  
  // Create download
  const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = `LogBook_${userName}_${format(new Date(), 'yyyy-MM-dd')}.xlsx`;
  link.click();
  window.URL.revokeObjectURL(url);
}

function getPilotRoleText(role: string): string {
  switch (role) {
    case 'PIC': return 'Piloto al Mando';
    case 'COPILOT': return 'Copiloto';
    case 'INSTRUCTOR': return 'Instructor';
    case 'STUDENT': return 'Alumno';
    default: return role;
  }
}