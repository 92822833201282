import React, { useState, useMemo } from 'react';
import { Filter } from 'lucide-react';
import type { Flight } from '../../types/flight';
import { FlightFilters } from './FlightFilters';
import { FlightSortButtons } from './FlightSortButtons';
import { FlightCard } from './FlightCard';

interface FlightListProps {
  flights: Flight[];
  onEdit: (flight: Flight) => void;
  onDelete: (id: string) => void;
}

type SortField = 'date' | 'totalTime' | 'aircraftRegistration' | 'departureLocation';
type SortOrder = 'asc' | 'desc';

export function FlightList({ flights, onEdit, onDelete }: FlightListProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const [sortField, setSortField] = useState<SortField>('date');
  const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
  const [filters, setFilters] = useState({
    aircraftType: '',
    flightConditions: '',
    timeOfDay: '',
  });

  const handleSort = (field: SortField) => {
    if (sortField === field) {
      setSortOrder(prev => prev === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };

  const handleFilterChange = (name: string, value: string) => {
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  const filteredAndSortedFlights = useMemo(() => {
    return flights
      .filter(flight => {
        const matchesSearch = 
          flight.aircraftRegistration.toLowerCase().includes(searchTerm.toLowerCase()) ||
          flight.departureLocation.toLowerCase().includes(searchTerm.toLowerCase()) ||
          flight.arrivalLocation.toLowerCase().includes(searchTerm.toLowerCase());

        const matchesFilters = 
          (!filters.aircraftType || flight.aircraftType === filters.aircraftType) &&
          (!filters.flightConditions || flight.flightConditions === filters.flightConditions) &&
          (!filters.timeOfDay || flight.timeOfDay === filters.timeOfDay);

        return matchesSearch && matchesFilters;
      })
      .sort((a, b) => {
        const multiplier = sortOrder === 'asc' ? 1 : -1;
        
        switch (sortField) {
          case 'date':
            return multiplier * (new Date(a.date).getTime() - new Date(b.date).getTime());
          case 'totalTime':
            const [aHours, aMinutes] = a.totalTime.split(':').map(Number);
            const [bHours, bMinutes] = b.totalTime.split(':').map(Number);
            const aTotal = aHours * 60 + aMinutes;
            const bTotal = bHours * 60 + bMinutes;
            return multiplier * (aTotal - bTotal);
          default:
            return multiplier * a[sortField].localeCompare(b[sortField]);
        }
      });
  }, [flights, searchTerm, sortField, sortOrder, filters]);

  return (
    <div className="space-y-6">
      {/* Filters Section */}
      <div className="bg-white p-4 rounded-xl border border-gray-200 space-y-4">
        <FlightFilters
          searchTerm={searchTerm}
          onSearchChange={setSearchTerm}
          filters={filters}
          onFilterChange={handleFilterChange}
          flights={flights}
        />
        <FlightSortButtons
          sortField={sortField}
          onSort={handleSort}
        />
      </div>

      {/* Results */}
      <div className="space-y-4">
        {filteredAndSortedFlights.length > 0 ? (
          filteredAndSortedFlights.map((flight) => (
            <FlightCard
              key={flight.id}
              flight={flight}
              onEdit={onEdit}
              onDelete={onDelete}
            />
          ))
        ) : (
          <div className="text-center py-12 bg-white rounded-xl border border-gray-200">
            <Filter className="h-12 w-12 text-gray-400 mx-auto mb-4" />
            <p className="text-gray-500">No se encontraron vuelos con los filtros seleccionados</p>
          </div>
        )}
      </div>
    </div>
  );
}