import React, { useState } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';
import { Shield, User, AlertCircle } from 'lucide-react';
import { LicenseModal } from '../components/profile/LicenseModal';
import { LicenseList } from '../components/profile/LicenseList';
import { ProfileInfo } from '../components/profile/ProfileInfo';
import { useLicenses } from '../hooks/useLicenses';
import { License } from '../types/license';

export default function Profile() {
  const { user } = useAuth();
  const [isEditing, setIsEditing] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showLicenseModal, setShowLicenseModal] = useState(false);
  const [selectedLicense, setSelectedLicense] = useState<License | null>(null);
  const [formData, setFormData] = useState({
    name: user?.name || '',
    phone: user?.phone || '',
    company: user?.company || '',
  });

  const { 
    licenses, 
    loading: loadingLicenses, 
    addLicense, 
    updateLicense, 
    deleteLicense 
  } = useLicenses();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user?.uid) return;

    setSaving(true);
    setError(null);

    try {
      const userRef = doc(db, 'users', user.uid);
      await updateDoc(userRef, {
        ...formData,
        updatedAt: new Date().toISOString()
      });

      setIsEditing(false);
    } catch (err) {
      console.error('Error updating profile:', err);
      setError('Error al actualizar el perfil. Por favor, inténtalo de nuevo.');
    } finally {
      setSaving(false);
    }
  };

  const handleLicenseSubmit = async (licenseData: Omit<License, 'id'>) => {
    try {
      if (selectedLicense) {
        await updateLicense(selectedLicense.id, licenseData);
      } else {
        await addLicense(licenseData);
      }
      setShowLicenseModal(false);
      setSelectedLicense(null);
    } catch (error) {
      console.error('Error handling license:', error);
    }
  };

  const handleDeleteLicense = async (licenseId: string) => {
    if (!window.confirm('¿Estás seguro de que deseas eliminar esta licencia?')) {
      return;
    }

    try {
      await deleteLicense(licenseId);
    } catch (error) {
      console.error('Error deleting license:', error);
    }
  };

  if (!user) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-center space-y-4">
          <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-red-100">
            <AlertCircle className="h-8 w-8 text-red-600" />
          </div>
          <p className="text-red-600">No se ha podido cargar el perfil</p>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      {/* Banner */}
      <div className="relative h-[300px] -mx-4 -mt-4 overflow-hidden rounded-xl">
        <img
          className="w-full h-full object-cover"
          src="https://images.unsplash.com/photo-1436891620584-47fd0e565afb?auto=format&fit=crop&q=80"
          alt="Profile banner"
        />
        <div className="absolute inset-0 bg-gradient-to-r from-blue-900/90 via-blue-800/70 to-transparent"></div>
        <div className="absolute inset-0 flex items-center px-8">
          <div className="flex items-center space-x-6">
            <div className="w-24 h-24 rounded-2xl bg-gradient-to-br from-blue-500 to-blue-600 flex items-center justify-center text-white text-4xl font-bold shadow-lg">
              {user.name?.charAt(0) || user.email?.charAt(0) || '?'}
            </div>
            <div>
              <h1 className="text-4xl font-bold text-white mb-2">
                {user.name || 'Usuario'}
              </h1>
              <p className="text-xl text-blue-100">Mi Perfil</p>
            </div>
          </div>
        </div>
      </div>

      {error && (
        <div className="bg-red-50 border border-red-200 rounded-xl p-4 flex items-center text-red-600">
          <AlertCircle className="h-5 w-5 mr-2 flex-shrink-0" />
          <p>{error}</p>
        </div>
      )}

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        {/* Main Info */}
        <div className="lg:col-span-2 space-y-6">
          <ProfileInfo
            user={user}
            isEditing={isEditing}
            saving={saving}
            formData={formData}
            onFormChange={(e) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))}
            onSubmit={handleSubmit}
            onCancel={() => {
              setIsEditing(false);
              setFormData({
                name: user.name || '',
                phone: user.phone || '',
                company: user.company || '',
              });
            }}
            onEdit={() => setIsEditing(true)}
          />

          {/* Licenses Section */}
          <div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
            <div className="p-6 border-b border-gray-200">
              <div className="flex justify-between items-center">
                <h2 className="text-lg font-semibold text-gray-900">Licencias y Certificaciones</h2>
                <button
                  onClick={() => {
                    setSelectedLicense(null);
                    setShowLicenseModal(true);
                  }}
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg text-sm font-medium hover:bg-blue-700 transition-colors"
                >
                  Añadir Licencia
                </button>
              </div>
            </div>

            <div className="p-6">
              {loadingLicenses ? (
                <div className="text-center py-8">
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600 mx-auto"></div>
                  <p className="mt-2 text-gray-500">Cargando licencias...</p>
                </div>
              ) : licenses.length > 0 ? (
                <LicenseList
                  licenses={licenses}
                  onEdit={(license) => {
                    setSelectedLicense(license);
                    setShowLicenseModal(true);
                  }}
                  onDelete={handleDeleteLicense}
                />
              ) : (
                <div className="text-center py-8">
                  <p className="text-gray-500">No hay licencias registradas</p>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Sidebar */}
        <div className="space-y-6">
          {/* Account Status */}
          <div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
            <div className="p-6">
              <h3 className="text-lg font-semibold text-gray-900 mb-4">Estado de la Cuenta</h3>
              <div className="space-y-4">
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    <Shield className="h-5 w-5 text-green-600" />
                    <span className="text-sm text-gray-600">Estado</span>
                  </div>
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                    Activo
                  </span>
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    <User className="h-5 w-5 text-blue-600" />
                    <span className="text-sm text-gray-600">Rol</span>
                  </div>
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                    {user.role === 'admin' ? 'Administrador' : 'Usuario'}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* License Modal */}
      <LicenseModal
        isOpen={showLicenseModal}
        onClose={() => {
          setShowLicenseModal(false);
          setSelectedLicense(null);
        }}
        onSubmit={handleLicenseSubmit}
        selectedLicense={selectedLicense}
      />
    </div>
  );
}