import React from 'react';
import { Pencil, Trash2, Calendar, Clock, MapPin, Plane } from 'lucide-react';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import type { Flight } from '../../types/flight';

interface FlightCardProps {
  flight: Flight;
  onEdit: (flight: Flight) => void;
  onDelete: (id: string) => void;
}

export function FlightCard({ flight, onEdit, onDelete }: FlightCardProps) {
  return (
    <div className="bg-white p-6 rounded-xl border border-gray-200 hover:shadow-md transition-all duration-200">
      <div className="flex justify-between items-start">
        <div className="space-y-4 flex-grow">
          <div className="flex flex-wrap items-center gap-4">
            <div className="flex items-center text-gray-600">
              <Calendar className="h-4 w-4 mr-2" />
              <span>{format(new Date(flight.date), 'dd MMM yyyy', { locale: es })}</span>
            </div>
            <div className="flex items-center text-gray-600">
              <Clock className="h-4 w-4 mr-2" />
              <span>{flight.totalTime}h</span>
            </div>
            <div className="flex items-center text-gray-600">
              <Plane className="h-4 w-4 mr-2" />
              <span>{flight.aircraftRegistration}</span>
            </div>
            <div className="flex items-center text-gray-600">
              <MapPin className="h-4 w-4 mr-2" />
              <span>{flight.departureLocation} → {flight.arrivalLocation}</span>
            </div>
          </div>
          
          <div className="flex flex-wrap gap-2">
            <span className="px-3 py-1 bg-blue-50 text-blue-700 text-sm font-medium rounded-full">
              {flight.aircraftType}
            </span>
            <span className="px-3 py-1 bg-green-50 text-green-700 text-sm font-medium rounded-full">
              {flight.pilotRole === 'PIC' ? 'Piloto al Mando' : 
               flight.pilotRole === 'COPILOT' ? 'Copiloto' :
               flight.pilotRole === 'INSTRUCTOR' ? 'Instructor' : 'Alumno'}
            </span>
            <span className="px-3 py-1 bg-purple-50 text-purple-700 text-sm font-medium rounded-full">
              {flight.flightConditions}
            </span>
            <span className="px-3 py-1 bg-amber-50 text-amber-700 text-sm font-medium rounded-full">
              {flight.timeOfDay === 'DAY' ? 'Día' : 'Noche'}
            </span>
          </div>

          {flight.remarks && (
            <p className="text-sm text-gray-600 italic">
              {flight.remarks}
            </p>
          )}
        </div>

        <div className="flex space-x-2 ml-4">
          <button
            onClick={() => onEdit(flight)}
            className="p-2 text-gray-400 hover:text-blue-500 hover:bg-blue-50 rounded-lg transition-colors"
            title="Editar vuelo"
          >
            <Pencil className="h-4 w-4" />
          </button>
          <button
            onClick={() => onDelete(flight.id)}
            className="p-2 text-gray-400 hover:text-red-500 hover:bg-red-50 rounded-lg transition-colors"
            title="Eliminar vuelo"
          >
            <Trash2 className="h-4 w-4" />
          </button>
        </div>
      </div>
    </div>
  );
}