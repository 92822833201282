import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { CheckCircle, Loader2 } from 'lucide-react';
import { Footer } from '../components/layout/Footer';

export function PaymentSuccess() {
  // ... resto del código del componente sin cambios ...

  if (loading) {
    return (
      <>
        <div className="min-h-screen flex items-center justify-center bg-gray-50">
          {/* ... resto del código del loading sin cambios ... */}
        </div>
        <Footer />
      </>
    );
  }

  if (error) {
    return (
      <>
        <div className="min-h-screen flex items-center justify-center bg-gray-50">
          {/* ... resto del código del error sin cambios ... */}
        </div>
        <Footer />
      </>
    );
  }

  return null;
}