import React, { useState, useEffect } from 'react';
import { X, Plane, Clock, MapPin, Calendar, User, Sun, Moon, CheckCircle } from 'lucide-react';
import type { Flight, AircraftType, PilotRole, FlightCondition, TimeOfDay } from '../../types/flight';
import type { Aircraft } from '../../types/aircraft';
import { useAuth } from '../../contexts/AuthContext';
import { useAircraft } from '../../hooks/useAircraft';

interface FlightFormProps {
  onSubmit: (flight: Omit<Flight, 'id'>) => void;
  onCancel: () => void;
  aircraft?: Aircraft;
  initialFlight?: Flight;
}

export function FlightForm({ onSubmit, onCancel, aircraft: preselectedAircraft, initialFlight }: FlightFormProps) {
  const { user } = useAuth();
  const { aircraft: userAircraft, loading: loadingAircraft } = useAircraft();
  const [selectedAircraft, setSelectedAircraft] = useState<Aircraft | null>(preselectedAircraft || null);
  const [formData, setFormData] = useState({
    date: new Date().toISOString().split('T')[0],
    departureTime: '',
    arrivalTime: '',
    departureLocation: '',
    arrivalLocation: '',
    aircraftId: '',
    aircraftType: '' as AircraftType,
    aircraftRegistration: '',
    pilotRole: '' as PilotRole,
    flightConditions: '' as FlightCondition,
    timeOfDay: '' as TimeOfDay,
    totalTime: '',
    landings: 0,
    remarks: ''
  });

  useEffect(() => {
    if (initialFlight) {
      setFormData(initialFlight);
      const aircraft = userAircraft.find(a => a.id === initialFlight.aircraftId);
      if (aircraft) {
        setSelectedAircraft(aircraft);
      }
    } else if (preselectedAircraft) {
      setFormData(prev => ({
        ...prev,
        aircraftId: preselectedAircraft.id,
        aircraftRegistration: preselectedAircraft.registration,
        aircraftType: preselectedAircraft.category === 'airplane' ? 
          preselectedAircraft.type.toUpperCase() as AircraftType : 
          `DRONE_${preselectedAircraft.type.toUpperCase()}` as AircraftType
      }));
      setSelectedAircraft(preselectedAircraft);
    }
  }, [initialFlight, preselectedAircraft, userAircraft]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!user?.uid || !selectedAircraft) return;

    const flightData = {
      ...formData,
      userId: user.uid,
      aircraftId: selectedAircraft.id,
      aircraftRegistration: selectedAircraft.registration,
      aircraftType: selectedAircraft.category === 'airplane' ? 
        selectedAircraft.type.toUpperCase() as AircraftType : 
        `DRONE_${selectedAircraft.type.toUpperCase()}` as AircraftType
    };

    onSubmit(flightData);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value, type } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'number' ? Number(value) : value
    }));
  };

  const handleAircraftSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const aircraft = userAircraft.find(a => a.id === e.target.value);
    setSelectedAircraft(aircraft || null);
  };

  const isDrone = selectedAircraft?.category === 'drone';

  if (loadingAircraft) {
    return (
      <div className="flex justify-center items-center p-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-8">
      <div className="flex justify-between items-center pb-6 border-b border-gray-200">
        <div>
          <h3 className="text-2xl font-bold text-gray-900">
            {initialFlight ? 'Editar Vuelo' : 'Registrar Nuevo Vuelo'}
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            {isDrone ? 'Completa los detalles de la operación con el dron' : 'Completa los detalles del vuelo realizado'}
          </p>
        </div>
        <button
          type="button"
          onClick={onCancel}
          className="p-2 text-gray-400 hover:text-gray-500 rounded-full hover:bg-gray-100 transition-colors"
        >
          <X className="h-5 w-5" />
        </button>
      </div>

      <div className="space-y-8">
        {/* Sección de Aeronave */}
        <div className="bg-blue-50/50 rounded-2xl p-6 border border-blue-100">
          <div className="flex items-center space-x-3 mb-4">
            <div className="p-2 bg-blue-100 rounded-xl">
              <Plane className="h-5 w-5 text-blue-600" />
            </div>
            <h4 className="font-semibold text-blue-900">Seleccionar Aeronave</h4>
          </div>
          <select
            name="aircraftId"
            value={selectedAircraft?.id || ''}
            onChange={handleAircraftSelect}
            className="w-full rounded-xl border-blue-200 focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 bg-white"
            required
          >
            <option value="">Seleccionar aeronave</option>
            {userAircraft.length > 0 ? (
              <>
                <optgroup label="Aeronaves">
                  {userAircraft
                    .filter(a => a.category === 'airplane')
                    .map(aircraft => (
                      <option key={aircraft.id} value={aircraft.id}>
                        {aircraft.registration} - {aircraft.model}
                      </option>
                    ))
                  }
                </optgroup>
                <optgroup label="Drones">
                  {userAircraft
                    .filter(a => a.category === 'drone')
                    .map(aircraft => (
                      <option key={aircraft.id} value={aircraft.id}>
                        {aircraft.registration} - {aircraft.model}
                      </option>
                    ))
                  }
                </optgroup>
              </>
            ) : (
              <option disabled>No hay aeronaves registradas</option>
            )}
          </select>
          {userAircraft.length === 0 && (
            <p className="mt-2 text-sm text-red-600">
              Debes registrar al menos una aeronave antes de poder registrar vuelos
            </p>
          )}
        </div>

        {selectedAircraft && (
          <>
            {/* Fecha y Rol */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="bg-purple-50/50 rounded-2xl p-6 border border-purple-100">
                <div className="flex items-center space-x-3 mb-4">
                  <div className="p-2 bg-purple-100 rounded-xl">
                    <Calendar className="h-5 w-5 text-purple-600" />
                  </div>
                  <h4 className="font-semibold text-purple-900">Fecha</h4>
                </div>
                <input
                  type="date"
                  name="date"
                  value={formData.date}
                  onChange={handleChange}
                  className="w-full rounded-xl border-purple-200 focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50 bg-white"
                  required
                />
              </div>

              <div className="bg-indigo-50/50 rounded-2xl p-6 border border-indigo-100">
                <div className="flex items-center space-x-3 mb-4">
                  <div className="p-2 bg-indigo-100 rounded-xl">
                    <User className="h-5 w-5 text-indigo-600" />
                  </div>
                  <h4 className="font-semibold text-indigo-900">Función del Piloto</h4>
                </div>
                <select
                  name="pilotRole"
                  value={formData.pilotRole}
                  onChange={handleChange}
                  className="w-full rounded-xl border-indigo-200 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 bg-white"
                  required
                >
                  <option value="">Seleccionar función</option>
                  <option value="PIC">Piloto al Mando</option>
                  <option value="COPILOT">Copiloto</option>
                  <option value="INSTRUCTOR">Instructor</option>
                  <option value="STUDENT">Alumno</option>
                </select>
              </div>
            </div>

            {/* Tiempos */}
            <div className="bg-green-50/50 rounded-2xl p-6 border border-green-100">
              <div className="flex items-center space-x-3 mb-4">
                <div className="p-2 bg-green-100 rounded-xl">
                  <Clock className="h-5 w-5 text-green-600" />
                </div>
                <h4 className="font-semibold text-green-900">Horarios</h4>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-green-900 mb-1">Hora Inicio</label>
                  <input
                    type="time"
                    name="departureTime"
                    value={formData.departureTime}
                    onChange={handleChange}
                    className="w-full rounded-xl border-green-200 focus:border-green-300 focus:ring focus:ring-green-200 focus:ring-opacity-50 bg-white"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-green-900 mb-1">Hora Fin</label>
                  <input
                    type="time"
                    name="arrivalTime"
                    value={formData.arrivalTime}
                    onChange={handleChange}
                    className="w-full rounded-xl border-green-200 focus:border-green-300 focus:ring focus:ring-green-200 focus:ring-opacity-50 bg-white"
                    required
                  />
                </div>
              </div>
            </div>

            {/* Ubicaciones */}
            <div className="bg-amber-50/50 rounded-2xl p-6 border border-amber-100">
              <div className="flex items-center space-x-3 mb-4">
                <div className="p-2 bg-amber-100 rounded-xl">
                  <MapPin className="h-5 w-5 text-amber-600" />
                </div>
                <h4 className="font-semibold text-amber-900">Ubicaciones</h4>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-amber-900 mb-1">
                    {isDrone ? 'Lugar de Despegue' : 'Aeródromo de Salida'}
                  </label>
                  <input
                    type="text"
                    name="departureLocation"
                    value={formData.departureLocation}
                    onChange={handleChange}
                    className="w-full rounded-xl border-amber-200 focus:border-amber-300 focus:ring focus:ring-amber-200 focus:ring-opacity-50 bg-white"
                    required
                    placeholder={isDrone ? 'Coordenadas o lugar' : 'LEMD'}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-amber-900 mb-1">
                    {isDrone ? 'Lugar de Aterrizaje' : 'Aeródromo de Llegada'}
                  </label>
                  <input
                    type="text"
                    name="arrivalLocation"
                    value={formData.arrivalLocation}
                    onChange={handleChange}
                    className="w-full rounded-xl border-amber-200 focus:border-amber-300 focus:ring focus:ring-amber-200 focus:ring-opacity-50 bg-white"
                    required
                    placeholder={isDrone ? 'Coordenadas o lugar' : 'LEBL'}
                  />
                </div>
              </div>
            </div>

            {/* Condiciones */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div className="bg-rose-50/50 rounded-2xl p-6 border border-rose-100">
                <div className="flex items-center space-x-3 mb-4">
                  <div className="p-2 bg-rose-100 rounded-xl">
                    <CheckCircle className="h-5 w-5 text-rose-600" />
                  </div>
                  <h4 className="font-semibold text-rose-900">Condiciones</h4>
                </div>
                <select
                  name="flightConditions"
                  value={formData.flightConditions}
                  onChange={handleChange}
                  className="w-full rounded-xl border-rose-200 focus:border-rose-300 focus:ring focus:ring-rose-200 focus:ring-opacity-50 bg-white"
                  required
                >
                  <option value="">Seleccionar condiciones</option>
                  {isDrone ? (
                    <>
                      <option value="VLOS">VLOS</option>
                      <option value="EVLOS">EVLOS</option>
                      <option value="BVLOS">BVLOS</option>
                    </>
                  ) : (
                    <>
                      <option value="VFR">VFR</option>
                      <option value="IFR">IFR</option>
                    </>
                  )}
                </select>
              </div>

              <div className="bg-cyan-50/50 rounded-2xl p-6 border border-cyan-100">
                <div className="flex items-center space-x-3 mb-4">
                  <div className="p-2 bg-cyan-100 rounded-xl">
                    {formData.timeOfDay === 'NIGHT' ? (
                      <Moon className="h-5 w-5 text-cyan-600" />
                    ) : (
                      <Sun className="h-5 w-5 text-cyan-600" />
                    )}
                  </div>
                  <h4 className="font-semibold text-cyan-900">Momento del Día</h4>
                </div>
                <select
                  name="timeOfDay"
                  value={formData.timeOfDay}
                  onChange={handleChange}
                  className="w-full rounded-xl border-cyan-200 focus:border-cyan-300 focus:ring focus:ring-cyan-200 focus:ring-opacity-50 bg-white"
                  required
                >
                  <option value="">Seleccionar momento</option>
                  <option value="DAY">Día</option>
                  <option value="NIGHT">Noche</option>
                </select>
              </div>

              <div className="bg-teal-50/50 rounded-2xl p-6 border border-teal-100">
                <div className="flex items-center space-x-3 mb-4">
                  <div className="p-2 bg-teal-100 rounded-xl">
                    <Clock className="h-5 w-5 text-teal-600" />
                  </div>
                  <h4 className="font-semibold text-teal-900">Tiempo Total</h4>
                </div>
                <input
                  type="text"
                  name="totalTime"
                  value={formData.totalTime}
                  onChange={handleChange}
                  placeholder="HH:MM"
                  pattern="^([0-9]{1,2}):([0-5][0-9])$"
                  title="Formato: HH:MM (ejemplo: 01:30)"
                  className="w-full rounded-xl border-teal-200 focus:border-teal-300 focus:ring focus:ring-teal-200 focus:ring-opacity-50 bg-white"
                  required
                />
              </div>
            </div>

            {/* Aterrizajes y Observaciones */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div className="bg-gray-50/50 rounded-2xl p-6 border border-gray-200">
                <div className="flex items-center space-x-3 mb-4">
                  <div className="p-2 bg-gray-100 rounded-xl">
                    <Plane className="h-5 w-5 text-gray-600" />
                  </div>
                  <h4 className="font-semibold text-gray-900">
                    {isDrone ? 'Despegues/Aterrizajes' : 'Aterrizajes'}
                  </h4>
                </div>
                <input
                  type="number"
                  name="landings"
                  value={formData.landings}
                  onChange={handleChange}
                  min="0"
                  className="w-full rounded-xl border-gray-200 focus:border-gray-300 focus:ring focus:ring-gray-200 focus:ring-opacity-50 bg-white"
                  required
                />
              </div>

              <div className="md:col-span-2 bg-gray-50/50 rounded-2xl p-6 border border-gray-200">
                <div className="flex items-center space-x-3 mb-4">
                  <div className="p-2 bg-gray-100 rounded-xl">
                    <MapPin className="h-5 w-5 text-gray-600" />
                  </div>
                  <h4 className="font-semibold text-gray-900">Observaciones</h4>
                </div>
                <textarea
                  name="remarks"
                  value={formData.remarks}
                  onChange={handleChange}
                  rows={3}
                  className="w-full rounded-xl border-gray-200 focus:border-gray-300 focus:ring focus:ring-gray-200 focus:ring-opacity-50 bg-white"
                  placeholder="Añade cualquier observación relevante..."
                />
              </div>
            </div>
          </>
        )}
      </div>

      <div className="flex justify-end space-x-4 pt-6 border-t border-gray-200">
        <button
          type="button"
          onClick={onCancel}
          className="px-6 py-2.5 border border-gray-300 rounded-xl text-gray-700 font-medium hover:bg-gray-50 transition-colors"
        >
          Cancelar
        </button>
        <button
          type="submit"
          disabled={!selectedAircraft}
          className="px-6 py-2.5 bg-gradient-to-r from-blue-600 to-blue-700 text-white rounded-xl font-medium hover:from-blue-700 hover:to-blue-800 transition-colors shadow-lg shadow-blue-500/30 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {initialFlight ? 'Guardar Cambios' : 'Guardar Vuelo'}
        </button>
      </div>
    </form>
  );
}