import React from 'react';
import { Pencil, Trash2 } from 'lucide-react';
import { License } from '../../types/license';

interface LicenseListProps {
  licenses: License[];
  onEdit: (license: License) => void;
  onDelete: (licenseId: string) => void;
}

export function LicenseList({ licenses, onEdit, onDelete }: LicenseListProps) {
  return (
    <div className="space-y-4">
      {licenses.map((license) => (
        <div key={license.id} className="flex justify-between items-center p-4 bg-gray-50 rounded-lg">
          <div>
            <h3 className="font-medium text-gray-900">{license.type}</h3>
            <p className="text-sm text-gray-500">Número: {license.number}</p>
            <p className="text-sm text-gray-500">
              Válida hasta: {new Date(license.expiryDate).toLocaleDateString()}
            </p>
          </div>
          <div className="flex space-x-2">
            <button
              onClick={() => onEdit(license)}
              className="p-2 text-gray-400 hover:text-blue-500 hover:bg-blue-50 rounded-lg transition-colors"
            >
              <Pencil className="h-5 w-5" />
            </button>
            <button
              onClick={() => onDelete(license.id)}
              className="p-2 text-gray-400 hover:text-red-500 hover:bg-red-50 rounded-lg transition-colors"
            >
              <Trash2 className="h-5 w-5" />
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}