import { useState, useEffect } from 'react';
import { collection, query, getDocs, addDoc, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { License } from '../types/license';
import { useAuth } from '../contexts/AuthContext';

export function useLicenses() {
  const [licenses, setLicenses] = useState<License[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { user } = useAuth();

  useEffect(() => {
    fetchLicenses();
  }, [user?.uid]);

  const fetchLicenses = async () => {
    if (!user?.uid) {
      setLoading(false);
      return;
    }

    try {
      const licensesRef = collection(db, `users/${user.uid}/licenses`);
      const licensesSnapshot = await getDocs(licensesRef);
      const licensesList = licensesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as License[];
      
      setLicenses(licensesList);
      setError(null);
    } catch (err) {
      console.error('Error fetching licenses:', err);
      setError('Error al cargar las licencias');
    } finally {
      setLoading(false);
    }
  };

  const addLicense = async (licenseData: Omit<License, 'id'>) => {
    if (!user?.uid) return;

    try {
      const licensesRef = collection(db, `users/${user.uid}/licenses`);
      const newLicense = {
        ...licenseData,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      };

      const docRef = await addDoc(licensesRef, newLicense);
      const license = { id: docRef.id, ...newLicense };
      setLicenses(prev => [...prev, license]);
      return license;
    } catch (error) {
      console.error('Error adding license:', error);
      throw new Error('Error al añadir la licencia');
    }
  };

  const updateLicense = async (licenseId: string, licenseData: Omit<License, 'id'>) => {
    if (!user?.uid) return;

    try {
      const licenseRef = doc(db, `users/${user.uid}/licenses`, licenseId);
      const updatedLicense = {
        ...licenseData,
        updatedAt: new Date().toISOString()
      };

      await updateDoc(licenseRef, updatedLicense);
      setLicenses(prev => prev.map(license => 
        license.id === licenseId 
          ? { ...license, ...updatedLicense }
          : license
      ));
    } catch (error) {
      console.error('Error updating license:', error);
      throw new Error('Error al actualizar la licencia');
    }
  };

  const deleteLicense = async (licenseId: string) => {
    if (!user?.uid) return;

    try {
      const licenseRef = doc(db, `users/${user.uid}/licenses`, licenseId);
      await deleteDoc(licenseRef);
      setLicenses(prev => prev.filter(license => license.id !== licenseId));
    } catch (error) {
      console.error('Error deleting license:', error);
      throw new Error('Error al eliminar la licencia');
    }
  };

  return {
    licenses,
    loading,
    error,
    addLicense,
    updateLicense,
    deleteLicense,
    refresh: fetchLicenses
  };
}