import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/layout/Layout';
import { Landing } from './pages/Landing';
import { Dashboard } from './pages/Dashboard';
import AircraftPage from './pages/Aircraft';
import { FlightLog } from './pages/FlightLog';
import { PilotLogbook } from './pages/PilotLogbook';
import { Statistics } from './pages/Statistics';
import { AIQueries } from './pages/AIQueries';
import Profile from './pages/Profile';
import { Admin } from './pages/Admin';
import { Login } from './pages/Login';
import { Register } from './pages/Register';
import { ForgotPassword } from './pages/ForgotPassword';
import { PaymentSuccess } from './pages/PaymentSuccess';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { FirebaseProvider } from './contexts/FirebaseContext';

function ProtectedRoute({ children }: { children: React.ReactNode }) {
  const { user, loading } = useAuth();

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  return <>{children}</>;
}

function AdminRoute({ children }: { children: React.ReactNode }) {
  const { user, loading } = useAuth();

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (!user || user.role !== 'admin') {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
}

function App() {
  const { user } = useAuth();

  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/" element={user ? <Navigate to="/dashboard" /> : <Landing />} />
      <Route path="/login" element={user ? <Navigate to="/dashboard" /> : <Login />} />
      <Route path="/registro" element={user ? <Navigate to="/dashboard" /> : <Register />} />
      <Route path="/recuperar-password" element={user ? <Navigate to="/dashboard" /> : <ForgotPassword />} />
      <Route path="/payment/success" element={<PaymentSuccess />} />

      {/* Protected Routes */}
      <Route element={<ProtectedRoute><Layout /></ProtectedRoute>}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/mi-registro" element={<PilotLogbook />} />
        <Route path="/aeronaves" element={<AircraftPage />} />
        <Route path="/aeronaves/:aircraftId/vuelos" element={<FlightLog />} />
        <Route path="/estadisticas" element={<Statistics />} />
        <Route path="/consultas-ia" element={<AIQueries />} />
        <Route path="/perfil" element={<Profile />} />
        
        {/* Admin Routes */}
        <Route path="/admin" element={
          <AdminRoute>
            <Admin />
          </AdminRoute>
        } />
      </Route>

      {/* Catch all route */}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

export default function AppWithProviders() {
  return (
    <AuthProvider>
      <FirebaseProvider>
        <App />
      </FirebaseProvider>
    </AuthProvider>
  );
}