import React from 'react';
import { Download } from 'lucide-react';
import { generatePDF } from '../../utils/pdfGenerator';
import { exportToExcel } from '../../utils/excelExport';
import type { Flight } from '../../types/flight';
import type { User } from '../../types/user';

interface ExportButtonProps {
  flights: Flight[];
  user: User;
}

export function ExportButton({ flights, user }: ExportButtonProps) {
  const handleExportPDF = () => {
    const totalHours = flights.reduce((acc, flight) => {
      const [hours, minutes] = flight.totalTime.split(':').map(Number);
      return acc + hours + (minutes / 60);
    }, 0);

    generatePDF(flights, user, totalHours);
  };

  const handleExportExcel = async () => {
    await exportToExcel(flights, user.name || user.email || 'Usuario');
  };

  return (
    <div className="flex space-x-4">
      <button
        onClick={handleExportPDF}
        className="inline-flex items-center px-6 py-3 bg-blue-600 text-white text-base font-medium rounded-lg hover:bg-blue-700 transition-all duration-200 shadow-lg"
      >
        <Download className="h-5 w-5 mr-2" />
        Exportar PDF
      </button>
      
      <button
        onClick={handleExportExcel}
        className="inline-flex items-center px-6 py-3 bg-green-600 text-white text-base font-medium rounded-lg hover:bg-green-700 transition-all duration-200 shadow-lg"
      >
        <Download className="h-5 w-5 mr-2" />
        Exportar Excel
      </button>
    </div>
  );
}