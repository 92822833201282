import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, addDoc, updateDoc, deleteDoc, collection } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';
import { Flight } from '../types/flight';
import { Aircraft } from '../types/aircraft';
import { FlightList } from '../components/flights/FlightList';
import { FlightForm } from '../components/flights/FlightForm';
import { useFlights } from '../hooks/useFlights';
import { ExportButton } from '../components/flights/ExportButton';
import { Plane, Plus, ArrowLeft, Loader2, AlertCircle, Clock, Calendar, MapPin } from 'lucide-react';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

export function FlightLog() {
  const { aircraftId } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [aircraft, setAircraft] = useState<Aircraft | null>(null);
  const [showForm, setShowForm] = useState(false);
  const [selectedFlight, setSelectedFlight] = useState<Flight | null>(null);
  const { flights, loading, error, setFlights } = useFlights(aircraftId);
  const [loadingAircraft, setLoadingAircraft] = useState(true);
  const [aircraftError, setAircraftError] = useState<string | null>(null);

  useEffect(() => {
    const fetchAircraft = async () => {
      if (!user?.uid || !aircraftId) return;

      try {
        const aircraftRef = doc(db, 'aircraft', aircraftId);
        const aircraftDoc = await getDoc(aircraftRef);

        if (!aircraftDoc.exists()) {
          throw new Error('Aeronave no encontrada');
        }

        const aircraftData = { id: aircraftDoc.id, ...aircraftDoc.data() } as Aircraft;
        
        if (aircraftData.userId !== user.uid) {
          throw new Error('No tienes permiso para ver esta aeronave');
        }

        setAircraft(aircraftData);
        setAircraftError(null);
      } catch (err) {
        console.error('Error fetching aircraft:', err);
        setAircraftError(err instanceof Error ? err.message : 'Error al cargar la aeronave');
      } finally {
        setLoadingAircraft(false);
      }
    };

    fetchAircraft();
  }, [user?.uid, aircraftId]);

  const handleSubmit = async (flightData: Omit<Flight, 'id'>) => {
    if (!user?.uid || !aircraft) return;

    try {
      const flightsRef = collection(db, `users/${user.uid}/flights`);
      const newFlightData = {
        ...flightData,
        userId: user.uid,
        aircraftId: aircraft.id,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      };

      const docRef = await addDoc(flightsRef, newFlightData);
      const newFlight = { id: docRef.id, ...newFlightData } as Flight;
      
      setFlights(prev => [newFlight, ...prev]);
      setShowForm(false);
    } catch (error) {
      console.error('Error adding flight:', error);
    }
  };

  const handleEdit = async (updatedFlight: Flight) => {
    if (!user?.uid) return;

    try {
      const flightRef = doc(db, `users/${user.uid}/flights`, updatedFlight.id);
      await updateDoc(flightRef, {
        ...updatedFlight,
        updatedAt: new Date().toISOString()
      });
      
      setFlights(prev => prev.map(f => 
        f.id === updatedFlight.id ? updatedFlight : f
      ));
      setSelectedFlight(null);
      setShowForm(false);
    } catch (error) {
      console.error('Error updating flight:', error);
    }
  };

  const handleDelete = async (flightId: string) => {
    if (!user?.uid || !window.confirm('¿Estás seguro de que deseas eliminar este vuelo?')) {
      return;
    }

    try {
      const flightRef = doc(db, `users/${user.uid}/flights`, flightId);
      await deleteDoc(flightRef);
      setFlights(prev => prev.filter(f => f.id !== flightId));
    } catch (error) {
      console.error('Error deleting flight:', error);
    }
  };

  if (loadingAircraft || loading) {
    return (
      <div className="flex items-center justify-center min-h-[calc(100vh-16rem)]">
        <div className="flex flex-col items-center space-y-4">
          <Loader2 className="h-8 w-8 animate-spin text-blue-600" />
          <p className="text-gray-600">Cargando registro de vuelos...</p>
        </div>
      </div>
    );
  }

  if (aircraftError || error || !aircraft) {
    return (
      <div className="flex items-center justify-center min-h-[calc(100vh-16rem)]">
        <div className="text-center space-y-4">
          <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-red-100">
            <AlertCircle className="h-8 w-8 text-red-600" />
          </div>
          <p className="text-red-600">{aircraftError || error || 'Error al cargar los datos'}</p>
          <button 
            onClick={() => navigate('/aeronaves')}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
          >
            Volver a Aeronaves
          </button>
        </div>
      </div>
    );
  }

  const totalHours = flights.reduce((acc, flight) => {
    const [hours, minutes] = flight.totalTime.split(':').map(Number);
    return acc + hours + (minutes / 60);
  }, 0);

  const uniqueLocations = new Set(
    flights.flatMap(f => [f.departureLocation, f.arrivalLocation])
  ).size;

  return (
    <div className="space-y-8">
      {/* Banner */}
      <div className="relative h-[300px] -mx-4 -mt-4 overflow-hidden rounded-xl">
        <img
          className="w-full h-full object-cover"
          src={aircraft.category === 'airplane' 
            ? "https://images.unsplash.com/photo-1464037866556-6812c9d1c72e?auto=format&fit=crop&q=80"
            : "https://images.unsplash.com/photo-1473968512647-3e447244af8f?auto=format&fit=crop&q=80"}
          alt={`${aircraft.registration} - ${aircraft.model}`}
        />
        <div className="absolute inset-0 bg-gradient-to-r from-blue-900/90 to-blue-800/70"></div>
        <div className="absolute inset-0 flex items-center px-8">
          <div className="max-w-3xl">
            <div className="flex items-center space-x-4 mb-6">
              <button
                onClick={() => navigate('/aeronaves')}
                className="p-2 text-white/80 hover:text-white hover:bg-white/10 rounded-lg transition-colors"
              >
                <ArrowLeft className="h-5 w-5" />
              </button>
              <div>
                <h1 className="text-4xl font-bold text-white">
                  {aircraft.registration}
                </h1>
                <p className="text-xl text-blue-100">{aircraft.model}</p>
              </div>
            </div>
            <div className="flex space-x-4">
              <button
                onClick={() => {
                  setSelectedFlight(null);
                  setShowForm(true);
                }}
                className="inline-flex items-center px-6 py-3 bg-white text-blue-900 text-base font-medium rounded-lg hover:bg-blue-50 transition-all duration-200 transform hover:scale-105 shadow-lg"
              >
                <Plus className="h-5 w-5 mr-2" />
                Registrar Nuevo Vuelo
              </button>
              {user && flights.length > 0 && (
                <ExportButton 
                  flights={flights} 
                  user={user} 
                  totalHours={totalHours} 
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Stats */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
        <div className="bg-white rounded-xl p-6 border border-gray-200">
          <div className="flex items-center space-x-3">
            <div className="p-2 bg-blue-100 rounded-lg">
              <Plane className="h-5 w-5 text-blue-600" />
            </div>
            <div>
              <p className="text-sm text-gray-500">Total Vuelos</p>
              <p className="text-2xl font-bold text-gray-900">{flights.length}</p>
            </div>
          </div>
        </div>
        
        <div className="bg-white rounded-xl p-6 border border-gray-200">
          <div className="flex items-center space-x-3">
            <div className="p-2 bg-green-100 rounded-lg">
              <Clock className="h-5 w-5 text-green-600" />
            </div>
            <div>
              <p className="text-sm text-gray-500">Horas Totales</p>
              <p className="text-2xl font-bold text-gray-900">
                {totalHours.toFixed(1)}h
              </p>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-xl p-6 border border-gray-200">
          <div className="flex items-center space-x-3">
            <div className="p-2 bg-purple-100 rounded-lg">
              <MapPin className="h-5 w-5 text-purple-600" />
            </div>
            <div>
              <p className="text-sm text-gray-500">Aeródromos</p>
              <p className="text-2xl font-bold text-gray-900">{uniqueLocations}</p>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-xl p-6 border border-gray-200">
          <div className="flex items-center space-x-3">
            <div className="p-2 bg-amber-100 rounded-lg">
              <Calendar className="h-5 w-5 text-amber-600" />
            </div>
            <div>
              <p className="text-sm text-gray-500">Último Vuelo</p>
              <p className="text-2xl font-bold text-gray-900">
                {flights.length > 0 
                  ? format(new Date(flights[0].date), 'dd/MM/yyyy', { locale: es })
                  : 'Sin vuelos'}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Flight List or Form */}
      <div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
        <div className="border-b border-gray-200 px-6 py-4">
          <div className="flex items-center justify-between">
            <div>
              <h2 className="text-lg font-semibold text-gray-900">Historial de Vuelos</h2>
              <p className="text-sm text-gray-500">
                Registro completo de vuelos con {aircraft.registration}
              </p>
            </div>
            <button
              onClick={() => {
                setSelectedFlight(null);
                setShowForm(true);
              }}
              className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
            >
              <Plus className="h-4 w-4 mr-2" />
              Registrar Vuelo
            </button>
          </div>
        </div>

        <div className="p-6">
          {showForm ? (
            <FlightForm
              onSubmit={selectedFlight ? handleEdit : handleSubmit}
              onCancel={() => {
                setShowForm(false);
                setSelectedFlight(null);
              }}
              initialFlight={selectedFlight}
              aircraft={aircraft}
            />
          ) : flights.length > 0 ? (
            <FlightList
              flights={flights}
              onEdit={(flight) => {
                setSelectedFlight(flight);
                setShowForm(true);
              }}
              onDelete={handleDelete}
            />
          ) : (
            <div className="text-center py-12">
              <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-blue-50 text-blue-600 mb-4">
                <Plane className="h-8 w-8" />
              </div>
              <h3 className="text-lg font-medium text-gray-900 mb-2">
                No hay vuelos registrados
              </h3>
              <p className="text-gray-500 mb-6">
                Comienza registrando tu primer vuelo con esta aeronave
              </p>
              <button
                onClick={() => {
                  setSelectedFlight(null);
                  setShowForm(true);
                }}
                className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
              >
                <Plus className="h-4 w-4 mr-2" />
                Registrar Primer Vuelo
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}