import { useState, useEffect } from 'react';
import { collection, query, where, getDocs, orderBy } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';
import type { Flight } from '../types/flight';

export function useFlights(aircraftId?: string) {
  const [flights, setFlights] = useState<Flight[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { user } = useAuth();

  useEffect(() => {
    async function fetchFlights() {
      if (!user?.uid) {
        setLoading(false);
        return;
      }

      try {
        // Use user-specific flights collection
        const flightsRef = collection(db, `users/${user.uid}/flights`);
        const constraints = [orderBy('date', 'desc')];
        
        // Add aircraft filter if specified
        if (aircraftId) {
          constraints.push(where('aircraftId', '==', aircraftId));
        }
        
        const flightsQuery = query(flightsRef, ...constraints);
        const snapshot = await getDocs(flightsQuery);
        
        const flightsList: Flight[] = [];
        snapshot.forEach((doc) => {
          flightsList.push({ 
            id: doc.id, 
            ...doc.data(),
            date: doc.data().date || new Date().toISOString().split('T')[0],
            totalTime: doc.data().totalTime || '0:00'
          } as Flight);
        });

        setFlights(flightsList);
        setError(null);
      } catch (err) {
        console.error('Error fetching flights:', err);
        setError('Error al cargar los vuelos. Por favor, intenta de nuevo.');
      } finally {
        setLoading(false);
      }
    }

    fetchFlights();
  }, [user?.uid, aircraftId]);

  return { flights, loading, error, setFlights };
}