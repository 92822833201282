import React from 'react';
import { Plane, Clock, Calendar, Award, PlaneTakeoff, MapPin, Shield } from 'lucide-react';
import { useFlightStats } from '../hooks/useFlightStats';
import { useAuth } from '../contexts/AuthContext';

export function Dashboard() {
  const { user } = useAuth();
  const { stats, loading, error } = useFlightStats();

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-center">
          <p className="text-red-600 mb-4">{error}</p>
          <button 
            onClick={() => window.location.reload()} 
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
          >
            Reintentar
          </button>
        </div>
      </div>
    );
  }

  const statCards = [
    {
      name: 'Aeronaves Registradas',
      value: stats.totalAircraft,
      icon: Plane,
      change: `+${stats.newAircraft || 0} último mes`,
      color: 'blue'
    },
    {
      name: 'Horas de Vuelo',
      value: `${stats.totalHours || 0}h`,
      icon: Clock,
      change: `+${stats.monthlyHours || 0}h último mes`,
      color: 'green'
    },
    {
      name: 'Vuelos Realizados',
      value: stats.totalFlights || 0,
      icon: PlaneTakeoff,
      change: `+${stats.monthlyFlights || 0} último mes`,
      color: 'purple'
    },
    {
      name: 'Licencias',
      value: stats.licenses || 0,
      icon: Award,
      change: `${stats.expiringLicenses || 0} por renovar`,
      color: 'amber'
    }
  ];

  return (
    <div className="space-y-8">
      <div className="relative h-[300px] -mx-4 -mt-4 overflow-hidden rounded-xl">
        <img
          className="w-full h-full object-cover"
          src="https://images.unsplash.com/photo-1436891620584-47fd0e565afb?auto=format&fit=crop&q=80"
          alt="Dashboard banner"
        />
        <div className="absolute inset-0 bg-gradient-to-r from-blue-900/90 to-blue-800/70"></div>
        <div className="absolute inset-0 flex flex-col justify-center px-8">
          <h1 className="text-4xl font-bold text-white mb-4">
            ¡Bienvenido a tu Logbook!
          </h1>
          <p className="text-xl text-blue-100">
            Gestiona tus vuelos y mantén un registro profesional de tu actividad aérea
          </p>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {statCards.map((stat) => {
          const Icon = stat.icon;
          return (
            <div key={stat.name} className="bg-white rounded-xl p-6 border border-gray-200">
              <div className="flex items-center">
                <div className={`inline-flex items-center justify-center w-12 h-12 rounded-lg bg-${stat.color}-50 text-${stat.color}-600`}>
                  <Icon className="h-6 w-6" />
                </div>
                <div className="ml-4">
                  <h3 className="text-2xl font-bold text-gray-900">{stat.value}</h3>
                  <p className="text-sm text-gray-500">{stat.name}</p>
                </div>
              </div>
              <div className="mt-4 text-sm text-green-600">
                {stat.change}
              </div>
            </div>
          );
        })}
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="bg-white rounded-xl p-6 border border-gray-200">
          <div className="flex items-center space-x-4 mb-6">
            <div className="h-12 w-12 bg-gradient-to-br from-purple-500 to-purple-600 rounded-lg flex items-center justify-center text-white">
              <MapPin className="h-6 w-6" />
            </div>
            <div>
              <h3 className="text-lg font-semibold text-gray-900">Últimos Destinos</h3>
              <p className="text-sm text-gray-500">Aeródromos visitados recientemente</p>
            </div>
          </div>
          <div className="space-y-3">
            {(stats.recentAirports || []).map((airport, index) => (
              <div key={index} className="flex items-center justify-between p-3 bg-purple-50 rounded-lg">
                <span className="text-purple-900 font-medium">{airport.code}</span>
                <span className="text-purple-600">{airport.visits} visitas</span>
              </div>
            ))}
            {(!stats.recentAirports || stats.recentAirports.length === 0) && (
              <p className="text-gray-500 text-center py-4">No hay destinos registrados</p>
            )}
          </div>
        </div>

        <div className="bg-white rounded-xl p-6 border border-gray-200">
          <div className="flex items-center space-x-4 mb-6">
            <div className="h-12 w-12 bg-gradient-to-br from-amber-500 to-amber-600 rounded-lg flex items-center justify-center text-white">
              <Shield className="h-6 w-6" />
            </div>
            <div>
              <h3 className="text-lg font-semibold text-gray-900">Condiciones de Vuelo</h3>
              <p className="text-sm text-gray-500">Distribución de tipos de vuelo</p>
            </div>
          </div>
          <div className="space-y-3">
            {/* Vuelos Tripulados */}
            <div className="flex items-center justify-between p-3 bg-amber-50 rounded-lg">
              <span className="text-amber-900 font-medium">VFR</span>
              <span className="text-amber-600">{stats.flightConditions?.vfr || 0} vuelos</span>
            </div>
            <div className="flex items-center justify-between p-3 bg-amber-50 rounded-lg">
              <span className="text-amber-900 font-medium">IFR</span>
              <span className="text-amber-600">{stats.flightConditions?.ifr || 0} vuelos</span>
            </div>

            {/* Vuelos con Drones */}
            <div className="flex items-center justify-between p-3 bg-amber-50 rounded-lg">
              <span className="text-amber-900 font-medium">VLOS</span>
              <span className="text-amber-600">{stats.flightConditions?.vlos || 0} vuelos</span>
            </div>
            <div className="flex items-center justify-between p-3 bg-amber-50 rounded-lg">
              <span className="text-amber-900 font-medium">EVLOS</span>
              <span className="text-amber-600">{stats.flightConditions?.evlos || 0} vuelos</span>
            </div>
            <div className="flex items-center justify-between p-3 bg-amber-50 rounded-lg">
              <span className="text-amber-900 font-medium">BVLOS</span>
              <span className="text-amber-600">{stats.flightConditions?.bvlos || 0} vuelos</span>
            </div>

            {/* Momento del día */}
            <div className="mt-4 pt-4 border-t border-amber-200">
              <div className="flex items-center justify-between p-3 bg-amber-50 rounded-lg">
                <span className="text-amber-900 font-medium">Día</span>
                <span className="text-amber-600">{stats.flightConditions?.day || 0} vuelos</span>
              </div>
              <div className="flex items-center justify-between p-3 bg-amber-50 rounded-lg mt-3">
                <span className="text-amber-900 font-medium">Noche</span>
                <span className="text-amber-600">{stats.flightConditions?.night || 0} vuelos</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}