import { loadStripe } from '@stripe/stripe-js';

export const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

export const createPaymentSession = async (email: string, priceId: string) => {
  try {
    const response = await fetch('/.netlify/functions/create-checkout-session', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        priceId,
      }),
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.details || 'Error creating payment session');
    }

    const session = await response.json();
    
    if (!session.url) {
      throw new Error('No checkout URL received');
    }

    return session;
  } catch (error) {
    console.error('Error creating payment session:', error);
    throw error;
  }
};